/* require('dotenv').config(); */
import {
  Alert,
  Avatar,
  useTheme,
  List,
  ListItem,
  FormControl,
  InputAdornment,
  Snackbar,
  CardHeader,
  Checkbox,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  ListItemText
} from '@mui/material';
import {
  SearchTwoTone,
  EditTwoTone,
  Add,
  PersonAdd,
  DeleteTwoTone,
  PlaylistAddCheckRounded
} from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  Box,
  Grid,
  Divider,
  Container,
  Card,
  Typography,
  CardContent,
  Button,
  styled
} from '@mui/material';
import { deepOrange, red } from '@mui/material/colors';
import axios from 'axios';
import React, { ChangeEvent } from 'react';
import { useState, useEffect } from 'react';
import Text from 'src/components/Text';
import Label from 'src/components/Label';
import { TipoDialogo, TipoExistencia } from 'src/utils/Helpers';
import Spinner from 'src/components/spinner';
import {
  ButtonSearch,
  OutlinedInputWrapper,
  TitleWrapper,
  TablecellHeaderDialog,
  TablecelDialog
} from '../styles';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import BulkActions from 'src/content/applications/Transactions/BulkActions';
import {
  CryptoOrder,
  CryptoOrderStatus,
  ProductStatus,
  Products
} from 'src/models/types';
import numeral from 'numeral';
import Tooltips from 'src/content/pages/Components/Tooltips';
import { createAxiosInstance } from 'src/api';
import { getProductosByDESCR } from 'src/services/services';
import { setCommentRange } from 'typescript';

interface RecentOrdersTableProps {
  className?: string;
  cryptoOrders: CryptoOrder[];
}

interface Filters {
  status?: CryptoOrderStatus | ProductStatus;
}

const getStatusLabel = (cryptoOrderStatus: CryptoOrderStatus): JSX.Element => {
  const map = {
    failed: {
      text: 'Failed',
      color: 'error'
    },
    completed: {
      text: 'Completed',
      color: 'success'
    },
    pending: {
      text: 'Pending',
      color: 'warning'
    }
  };

  const { text, color }: any = map[cryptoOrderStatus];

  return <Label color={color}>{text}</Label>;
};

const applyFilters = (
  cryptoOrders: CryptoOrder[],
  filters: Filters
): CryptoOrder[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;
    if (filters.status && cryptoOrder.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  return cryptoOrders.slice(page * limit, page * limit + limit);
};

const SimpleDialog = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [dpto, setDpto] = useState();

  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };
  const fetDeositos = () => {
    setLoading(true);
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/archivos/dpto`)
          .then((result) => {
            setDpto(result.data?.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };

  const fetchProductos = async (descr: string) => {
    if (inputValue && inputValue.length > 0) {
      try {
        descr = inputValue.toString() === '*' ? '' : descr;
        setLoading(true);
        const { Data, Messages } = await getProductosByDESCR(descr);
        setEncontrados(Data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching productos:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setEncontrados([]);
    }
  };

  const handleClose = () => {
    onClose();
  };
  const handleClose2 = () => {
    switch (tipoDialogo) {
      case 'exis':
        setOpen2(false);
        break;
      case 'product':
        setOpen1(false);
        break;
      case 'changeExis':
        setOpen3(false);
        break;
      case 'product-cliente':
        setOpen4(false);
        break;
      default:
        break;
    }
  };
  const handleListItemClick = (value) => {
    if (dpto) {
      setSelectedValue(value);
      switch (tipoDialogo) {
        case 'exis':
          setOpen2(true);
          break;
        case 'product':
          setOpen1(true);
          break;
        case 'product-cliente':
          setOpen4(true);
          break;
        case 'changeExis':
          setOpen3(true);
          break;
        default:
          break;
      }
    } else {
      setState({
        ...state,
        openSb: true,
        message: 'Espere mientras cargan los depositos...'
      });
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchProductos(inputValue);
    }
  };
  useEffect(() => {
    if (open) fetDeositos();
  }, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1
            }}
          >
            <TitleWrapper variant="h5">Seleccionar Productos</TitleWrapper>
          </Box>
        </DialogTitle>
        <Container maxWidth="sm" sx={{ p: 2 }}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInputWrapper
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleEnterKeyPress}
              placeholder="Buscar producto por nombre..."
              endAdornment={
                <InputAdornment position="end">
                  <ButtonSearch
                    variant="contained"
                    size="small"
                    onClick={() => fetchProductos(inputValue)}
                  >
                    Buscar
                  </ButtonSearch>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchTwoTone />
                </InputAdornment>
              }
            />
          </FormControl>
          {loading && <Spinner />}
          {encontrados?.length > 0 ? (
            <Divider
              sx={{ my: 2, color: theme.colors.error.main }}
            >{`${encontrados?.length} Encontrados (${filters})`}</Divider>
          ) : null}
          <List sx={{ pt: 0 }}>
            {encontrados?.length > 0 ? (
              encontrados.map((item: any) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(item)}
                  key={item.ID}
                  alignItems="center"
                  sx={{ p: 0, pb: 1 }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item xs={8} sx={{ textAlign: 'left' }}>
                      <Typography variant="body1">
                        {item.DESCR.trim()}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                      <Typography variant="h5">
                        $ {item.PRECIO1 ? item.PRECIO1.toFixed(2) : '0.00'}
                      </Typography>
                    </Grid>
                    <Divider></Divider>
                  </Grid>
                  {/*  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                      <Done />
                    </Avatar>
                  </ListItemAvatar> */}
                </ListItem>
              ))
            ) : (
              <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                Producto no encontrado!
              </Typography>
            )}
          </List>
          {/* <Button href="/overview" variant="outlined">
                Seleccionar
              </Button> */}
        </Container>
      </Dialog>
      {tipoDialogo === 'product' ? (
        <Promociones
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'product-cliente' ? (
        <ProductosQR
          dptos={dpto}
          selectedValue={selectedValue}
          open={open4}
          onClose={handleClose2}
        />
      ) : tipoDialogo == 'exis' ? (
        <MiPerfil
          dptos={dpto}
          selectedValue={selectedValue}
          open={open2}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'changeExis' ? (
        <MisFacturas
          dptos={dpto}
          selectedValue={selectedValue}
          open={open3}
          onClose={handleClose2}
        />
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tipoDialogo: TipoDialogo
};

//Styles

//Inventario
const ProductosQR = (props) => {
  const theme = useTheme();
  const { onClose, selectedValue, open, dptos } = props;
  const [inputValue, setInputValue] = useState('');
  const [tipoExistencia, setTipoExistencia] =
    useState<TipoExistencia>('Agotados');
  const [alert, setAlert] = useState<any>('error');
  const [loading, setLoading] = useState(false);
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleClose = () => {
    onClose(true);
  };
  const fetchStocks = (codigo: string) => {
    setLoading(true);
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/productos/stock`, {
          data: {
            CODIGO: `${codigo.trim()}`
          }
        })
          .then((result) => {
            const rawData = result.data?.data;
            if (rawData && Array.isArray(rawData) && rawData.length > 0) {
              let exis = 0;
              rawData.forEach((stock: any) => {
                //TODO: Asignar deposito Princial del context Settings
                if (stock.DPTO === '01') {
                  setTipoExistencia(
                    stock.ENT - stock.SAL > 0 ? 'Disponible' : 'Agotados'
                  );
                  setAlert(stock.ENT - stock.SAL > 0 ? 'success' : 'error');
                  return;
                }
              });
            } else {
              setTipoExistencia('Agotados');
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };

  useEffect(() => {
    if (selectedValue && open) {
      fetchStocks(selectedValue?.CODIGO);
    }
  }, [open]);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: theme.colors.error.main,
            p: 1
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <TitleWrapper variant="h5">Detalle de Producto</TitleWrapper>
            </Grid>
            <Divider></Divider>
          </Grid>
        </Box>
      </DialogTitle>
      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Box>
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ p: 2 }}>
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid
                      container
                      xs={12}
                      sm={3}
                      md={3}
                      justifyContent={'center'}
                      alignItems={'center'}
                      p={1}
                    >
                      <Avatar
                        sx={{
                          bgcolor: deepOrange[500],
                          height: '150px',
                          width: '100px'
                        }}
                        variant="square"
                        src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcT9zSAvyp1-CUnGyfrQA7FVTbiX5t3jVsm-KMLEX2Uu174pnkADINXNmpgQO9MZ"
                      >
                        Foto
                      </Avatar>
                    </Grid>
                    <Grid item container spacing={0} xs={12} sm={9} md={9}>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={4}
                        textAlign={{ sm: 'right' }}
                      >
                        <Box pr={3} pb={2}>
                          Nombre:
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={8} md={8}>
                        <Text color="black">
                          <b>{selectedValue?.DESCR?.trim()}</b>
                        </Text>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={4}
                        textAlign={{ sm: 'right' }}
                      >
                        <Box pr={1} pb={1}>
                          Precio Bs.:
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={8} md={8}>
                        <Text color="black">
                          <b>
                            {selectedValue?.PRECIO
                              ? selectedValue.PRECIO.toFixed(2)
                              : '0.00'}
                          </b>
                        </Text>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={4}
                        textAlign={{ sm: 'right' }}
                      >
                        <Box pr={1} pb={1}>
                          Precio $:
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={8} md={8}>
                        <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                          <Text color="black">
                            <b>
                              {selectedValue?.PRECIO1
                                ? selectedValue.PRECIO1.toFixed(2)
                                : '0.00'}
                            </b>
                          </Text>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
              <Divider />
              {loading && <Spinner />}
              {!loading && (
                <Box p={0.5} alignItems="center" justifyContent="space-between">
                  <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={6} sm={6} md={6} textAlign={{ sm: 'left' }}>
                      <Alert variant="standard" severity={alert}>
                        <strong>{tipoExistencia.toUpperCase()}</strong>
                      </Alert>
                    </Grid>
                    {tipoExistencia === 'Disponible' ? (
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        textAlign={{ sm: 'right' }}
                      >
                        <Tooltip arrow title="Agregar a tu Lista de deseos">
                          <Button
                            size="large"
                            variant="contained"
                            color="warning"
                            startIcon={
                              <PlaylistAddCheckRounded fontSize="small" />
                            }
                          >
                            Lo Quiero
                          </Button>
                        </Tooltip>
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              )}
            </Card>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};
const MisDeseos = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo, items } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [open1, setOpen1] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [dpto, setDpto] = useState();
  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  const applyFilters = (
    cryptoOrders: Products[],
    filters: Filters
  ): Products[] => {
    return cryptoOrders.filter((cryptoOrder) => {
      let matches = true;
      if (filters.status && cryptoOrder.status !== filters.status) {
        matches = false;
      }
      return matches;
    });
  };

  const applyPagination = (
    cryptoOrders: Products[],
    page: number,
    limit: number
  ): Products[] => {
    return cryptoOrders.slice(page * limit, page * limit + limit);
  };

  const selectedOptions = [
    {
      id: 'all',
      name: 'Todos'
    },
    {
      id: 'selected',
      name: 'Seleccionado'
    },
    {
      id: 'unselected',
      name: 'No Seleccionados'
    }
  ];

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'todos') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSelectAllCryptoOrders = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedCryptoOrders(
      event.target.checked ? items.map((cryptoOrder) => cryptoOrder.id) : []
    );
  };

  const handleSelectOneCryptoOrder = (
    event: ChangeEvent<HTMLInputElement>,
    cryptoOrderId: string
  ): void => {
    if (!selectedCryptoOrders.includes(cryptoOrderId)) {
      setSelectedCryptoOrders((prevSelected) => [
        ...prevSelected,
        cryptoOrderId
      ]);
    } else {
      setSelectedCryptoOrders((prevSelected) =>
        prevSelected.filter((id) => id !== cryptoOrderId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredCryptoOrders = applyFilters(items, filters);
  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < items.length;
  const selectedAllCryptoOrders = selectedCryptoOrders.length === items.length;

  const fetDeositos = () => {
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/archivos/dpto`)
          .then((result) => {
            setDpto(result.data?.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };
  const fetchProductos = async (descr: string) => {
    if (inputValue && inputValue.length > 0) {
      try {
        descr = inputValue.toString() === '*' ? '' : descr;
        setLoading(true);
        const { Data, Messages } = await getProductosByDESCR(descr);
        setEncontrados(Data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching productos:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setEncontrados([]);
    }
  };
  const handleClose = () => {
    onClose();
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleListItemClick = (value) => {
    if (dpto) {
      setSelectedValue(value);
      setOpen1(true);
    } else {
      setState({
        ...state,
        openSb: true,
        message: 'Espere mientras cargan los datos...'
      });
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchProductos(inputValue);
    }
  };
  useEffect(() => {
    if (open) fetDeositos();
  }, [open]);

  useEffect(() => {
    // setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1
            }}
          >
            <TitleWrapper variant="h5">Lista de Deseos</TitleWrapper>
          </Box>
        </DialogTitle>
        <Container maxWidth="sm" sx={{ p: 1 }}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInputWrapper
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleEnterKeyPress}
              placeholder="Buscar factura por Número..."
              endAdornment={
                <InputAdornment position="end">
                  <ButtonSearch
                    variant="contained"
                    size="small"
                    onClick={() => fetchProductos(inputValue)}
                  >
                    Buscar
                  </ButtonSearch>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchTwoTone />
                </InputAdornment>
              }
            />
          </FormControl>
          {loading && <Spinner />}
          {encontrados?.length > 0 ? (
            <Divider
              sx={{ my: 2, color: theme.colors.error.main }}
            >{`${encontrados?.length} Encontrados (${filters})`}</Divider>
          ) : null}

          <Card>
            {selectedBulkActions && (
              <Box flex={1} p={1}>
                <BulkActions />
              </Box>
            )}
            <CardHeader
              action={
                <Box width={150}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={filters.status || 'Todos'}
                      onChange={handleStatusChange}
                      label="Status"
                      autoWidth
                    >
                      {selectedOptions.map((statusOption) => (
                        <MenuItem key={statusOption.id} value={statusOption.id}>
                          {statusOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              }
              title={
                <Box pr={1}>
                  <Grid
                    item
                    container
                    spacing={0}
                    sm={10}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Grid item xs={6} sm={6} md={6} textAlign={{ sm: 'right' }}>
                      <Box pr={3}>Total Gral.:</Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} textAlign={{ sm: 'right' }}>
                      <Text color="black">
                        <b>1000</b>
                      </Text>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} textAlign={{ sm: 'right' }}>
                      <Box pr={3}>Selección:</Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} textAlign={{ sm: 'right' }}>
                      <Text color="black">
                        <b>0</b>
                      </Text>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} textAlign={{ sm: 'right' }}>
                      <Box pr={3}>Total:</Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} textAlign={{ sm: 'right' }}>
                      <Text color="black">
                        <b>
                          {selectedValue?.PRECIO
                            ? selectedValue.PRECIO.toFixed(2)
                            : '0.00'}
                        </b>
                      </Text>
                    </Grid>
                  </Grid>
                </Box>
              }
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={selectedAllCryptoOrders}
                        indeterminate={selectedSomeCryptoOrders}
                        onChange={handleSelectAllCryptoOrders}
                      />
                    </TableCell>
                    <TablecellHeaderDialog
                      sx={{ textAlign: 'left', minWidth: 150 }}
                    >
                      Descr
                    </TablecellHeaderDialog>
                    <TablecellHeaderDialog width={50}>
                      Precio
                    </TablecellHeaderDialog>
                    <TablecellHeaderDialog width={100}>
                      Total
                    </TablecellHeaderDialog>
                    <TablecellHeaderDialog width={50}>
                      Editar
                    </TablecellHeaderDialog>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedCryptoOrders.map((cryptoOrder) => {
                    const isCryptoOrderSelected = selectedCryptoOrders.includes(
                      cryptoOrder.id
                    );
                    return (
                      <TableRow
                        hover
                        key={cryptoOrder.id}
                        selected={isCryptoOrderSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isCryptoOrderSelected}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              handleSelectOneCryptoOrder(event, cryptoOrder.id)
                            }
                            value={isCryptoOrderSelected}
                          />
                        </TableCell>
                        <TablecelDialog sx={{ textAlign: 'left' }}>
                          <Tooltip
                            arrow
                            placement="top"
                            title={cryptoOrder.desc}
                          >
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              gutterBottom
                            >
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                              >
                                {cryptoOrder.desc.substring(0, 50)}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                noWrap
                              >
                                COD:{cryptoOrder.id}
                              </Typography>
                            </Typography>
                            {/*  <Typography variant="body2" color="text.secondary" noWrap>
                                                        {format(cryptoOrder.orderDate, 'MMMM dd yyyy')}
                                                    </Typography> */}
                          </Tooltip>
                        </TablecelDialog>
                        <TablecelDialog align="right">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            1x
                            <strong>
                              {numeral(cryptoOrder.precio).format(`Bs 0,0.00`)}
                            </strong>
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            noWrap
                          >
                            {numeral(cryptoOrder.precio2).format(`$ 0,0.00`)}
                          </Typography>
                        </TablecelDialog>
                        <TablecelDialog width={100} align="right">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {numeral(cryptoOrder.precio).format(`Bs 0,0.00`)}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            noWrap
                          >
                            {numeral(cryptoOrder.precio).format(`$ 0,0.00`)}
                          </Typography>
                        </TablecelDialog>
                        {/* <TableCell align="right">
                                                    {getStatusLabel(cryptoOrder.status)}
                                                </TableCell>  */}
                        <TableCell align="right">
                          <Tooltip title="Edit Order" arrow>
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: theme.palette.primary.main
                              }}
                              color="inherit"
                              size="small"
                            >
                              <EditTwoTone fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Order" arrow>
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.error.lighter
                                },
                                color: theme.palette.error.main
                              }}
                              color="inherit"
                              size="small"
                            >
                              <DeleteTwoTone fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={filteredCryptoOrders.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 30]}
              />
            </Box>
          </Card>
        </Container>
      </Dialog>

      {tipoDialogo === 'product-cliente' ? (
        <Promociones
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'product-cliente' ? (
        <ProductosQR
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo == 'exis' ? (
        <MiPerfil
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'changeExis' ? (
        <MisFacturas
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};
const Promociones = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [dpto, setDpto] = useState();

  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };
  const fetDeositos = () => {
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/archivos/dpto`)
          .then((result) => {
            setDpto(result.data?.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };

  const fetchProductos = async (descr: string) => {
    if (inputValue && inputValue.length > 0) {
      try {
        descr = inputValue.toString() === '*' ? '' : descr;
        setLoading(true);
        const { Data, Messages } = await getProductosByDESCR(descr);
        setEncontrados(Data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching productos:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setEncontrados([]);
    }
  };

  const handleClose = () => {
    onClose();
  };
  const handleClose2 = () => {
    switch (tipoDialogo) {
      case 'exis':
        setOpen2(false);
        break;
      case 'product':
        setOpen1(false);
        break;
      case 'changeExis':
        setOpen3(false);
        break;
      case 'product-cliente':
        setOpen4(false);
        break;
      default:
        break;
    }
  };
  const handleListItemClick = (value) => {
    if (dpto) {
      setSelectedValue(value);
      switch (tipoDialogo) {
        case 'exis':
          setOpen2(true);
          break;
        case 'product':
          setOpen1(true);
          break;
        case 'product-cliente':
          setOpen4(true);
          break;
        case 'changeExis':
          setOpen3(true);
          break;
        default:
          break;
      }
    } else {
      setState({
        ...state,
        openSb: true,
        message: 'Espere mientras cargan los depositos...'
      });
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchProductos(inputValue);
    }
  };
  useEffect(() => {
    if (open) fetDeositos();
  }, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1
            }}
          >
            <TitleWrapper variant="h5">Promociones</TitleWrapper>
          </Box>
        </DialogTitle>
        <Container maxWidth="sm" sx={{ p: 2 }}>
          {loading && <Spinner />}
          {encontrados?.length > 0 ? (
            <Divider
              sx={{ my: 2, color: theme.colors.error.main }}
            >{`${encontrados?.length} Encontrados (${filters})`}</Divider>
          ) : null}
          <List sx={{ pt: 0 }}>
            {encontrados?.length > 0 ? (
              encontrados.map((item: any) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(item)}
                  key={item.ID}
                  alignItems="center"
                  sx={{ p: 0, pb: 1 }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item xs={8} sx={{ textAlign: 'left' }}>
                      <Typography variant="body1">
                        {item.DESCR.trim()}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                      <Typography variant="h5">
                        $ {item.PRECIO1 ? item.PRECIO1.toFixed(2) : '0.00'}
                      </Typography>
                    </Grid>
                    <Divider></Divider>
                  </Grid>
                </ListItem>
              ))
            ) : (
              <ListItem
                button
                onClick={() => {}}
                key="Vacio"
                alignItems="center"
                sx={{ p: 0, pb: 1 }}
              >
                <Grid container justifyContent="center">
                  <Grid item xs={8} sx={{ textAlign: 'center' }}>
                    <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                      Sin Facturas!
                    </Typography>
                  </Grid>
                  <Divider></Divider>
                </Grid>
              </ListItem>
            )}
          </List>
        </Container>
      </Dialog>
      {tipoDialogo === 'product' ? (
        <Promociones
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'product-cliente' ? (
        <ProductosQR
          dptos={dpto}
          selectedValue={selectedValue}
          open={open4}
          onClose={handleClose2}
        />
      ) : tipoDialogo == 'exis' ? (
        <MiPerfil
          dptos={dpto}
          selectedValue={selectedValue}
          open={open2}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'changeExis' ? (
        <MisFacturas
          dptos={dpto}
          selectedValue={selectedValue}
          open={open3}
          onClose={handleClose2}
        />
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};
const MiPerfil = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [open1, setOpen1] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [dpto, setDpto] = useState();
  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };
  const fetDeositos = () => {
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/archivos/dpto`)
          .then((result) => {
            setDpto(result.data?.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };
  const fetchProductos = async (descr: string) => {
    if (inputValue && inputValue.length > 0) {
      try {
        descr = inputValue.toString() === '*' ? '' : descr;
        setLoading(true);
        const { Data, Messages } = await getProductosByDESCR(descr);
        setEncontrados(Data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching productos:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setEncontrados([]);
    }
  };
  const handleClose = () => {
    onClose();
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleListItemClick = (value) => {
    if (dpto) {
      setSelectedValue(value);
      setOpen1(true);
    } else {
      setState({
        ...state,
        openSb: true,
        message: 'Espere mientras cargan los datos...'
      });
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchProductos(inputValue);
    }
  };
  useEffect(() => {
    if (open) fetDeositos();
  }, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1,
              mb: 2
            }}
          >
            <TitleWrapper variant="h5">Mi Perfil</TitleWrapper>
            <Button
              size="small"
              variant="contained"
              startIcon={<PersonAdd fontSize="small" />}
            >
              Registrarse
            </Button>
          </Box>
        </DialogTitle>
        <Container maxWidth="sm" sx={{ p: 2 }}>
          {loading && <Spinner />}
          {encontrados?.length > 0 ? (
            <Divider
              sx={{ my: 2, color: theme.colors.error.main }}
            >{`${encontrados?.length} Encontrados (${filters})`}</Divider>
          ) : null}
          <List sx={{ pt: 0 }}>
            {encontrados?.length > 0 ? (
              encontrados.map((item: any) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(item)}
                  key={item.ID}
                  alignItems="center"
                  sx={{ p: 0, pb: 1 }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item xs={8} sx={{ textAlign: 'left' }}>
                      <Typography variant="body1">
                        {item.DESCR.trim()}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                      <Typography variant="h5">
                        $ {item.PRECIO1 ? item.PRECIO1.toFixed(2) : '0.00'}
                      </Typography>
                    </Grid>
                    <Divider></Divider>
                  </Grid>
                </ListItem>
              ))
            ) : (
              <ListItem
                button
                onClick={() => {}}
                key="Vacio"
                alignItems="center"
                sx={{ p: 0, pb: 1 }}
              >
                <Grid container justifyContent="center">
                  <Grid item xs={8} sx={{ textAlign: 'center' }}>
                    <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                      No estas Registrado! 😢
                    </Typography>
                  </Grid>
                  <Divider></Divider>
                </Grid>
              </ListItem>
            )}
          </List>
        </Container>
      </Dialog>

      {tipoDialogo === 'product-cliente' ? (
        <Promociones
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'product-cliente' ? (
        <ProductosQR
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo == 'exis' ? (
        <MiPerfil
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'changeExis' ? (
        <MisFacturas
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};
const MisFacturas = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo, cryptoOrders } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [open1, setOpen1] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [dpto, setDpto] = useState();
  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  const statusOptions = [
    {
      id: 'all',
      name: 'All'
    },
    {
      id: 'completed',
      name: 'Completed'
    },
    {
      id: 'pending',
      name: 'Pending'
    },
    {
      id: 'failed',
      name: 'Failed'
    }
  ];

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSelectAllCryptoOrders = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedCryptoOrders(
      event.target.checked
        ? cryptoOrders.map((cryptoOrder) => cryptoOrder.id)
        : []
    );
  };

  const handleSelectOneCryptoOrder = (
    event: ChangeEvent<HTMLInputElement>,
    cryptoOrderId: string
  ): void => {
    if (!selectedCryptoOrders.includes(cryptoOrderId)) {
      setSelectedCryptoOrders((prevSelected) => [
        ...prevSelected,
        cryptoOrderId
      ]);
    } else {
      setSelectedCryptoOrders((prevSelected) =>
        prevSelected.filter((id) => id !== cryptoOrderId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredCryptoOrders = applyFilters(cryptoOrders, filters);
  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < cryptoOrders.length;
  const selectedAllCryptoOrders =
    selectedCryptoOrders.length === cryptoOrders.length;

  const fetDeositos = () => {
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/archivos/dpto`)
          .then((result) => {
            setDpto(result.data?.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };
  const fetchProductos = async (descr: string) => {
    if (inputValue && inputValue.length > 0) {
      try {
        descr = inputValue.toString() === '*' ? '' : descr;
        setLoading(true);
        const { Data, Messages } = await getProductosByDESCR(descr);
        setEncontrados(Data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching productos:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setEncontrados([]);
    }
  };
  const handleClose = () => {
    onClose();
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleListItemClick = (value) => {
    if (dpto) {
      setSelectedValue(value);
      setOpen1(true);
    } else {
      setState({
        ...state,
        openSb: true,
        message: 'Espere mientras cargan los datos...'
      });
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchProductos(inputValue);
    }
  };
  useEffect(() => {
    if (open) fetDeositos();
  }, [open]);

  useEffect(() => {
    // setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1,
              mb: 2
            }}
          >
            <TitleWrapper variant="h5">Mis Facturas</TitleWrapper>
          </Box>
        </DialogTitle>
        <Container maxWidth="sm" sx={{ p: 2 }}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInputWrapper
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleEnterKeyPress}
              placeholder="Buscar factura por Número..."
              endAdornment={
                <InputAdornment position="end">
                  <ButtonSearch
                    variant="contained"
                    size="small"
                    onClick={() => fetchProductos(inputValue)}
                  >
                    Buscar
                  </ButtonSearch>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchTwoTone />
                </InputAdornment>
              }
            />
          </FormControl>
          {loading && <Spinner />}
          {encontrados?.length > 0 ? (
            <Divider
              sx={{ my: 2, color: theme.colors.error.main }}
            >{`${encontrados?.length} Encontrados (${filters})`}</Divider>
          ) : null}
          {/*  <List sx={{ pt: 0 }}>
                        {encontrados?.length > 0 ? encontrados.map((item: any) => (
                            <ListItem
                                button
                                onClick={() => handleListItemClick(item)}
                                key={item.ID}
                                alignItems='center'
                                sx={{ p: 0, pb: 1 }}
                            >
                                <Grid container
                                    justifyContent="space-between">
                                    <Grid item xs={8} sx={{ textAlign: 'left' }}>
                                        <Typography variant="body1">{item.DESCR.trim()}</Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                        <Typography variant='h5'>
                                            $ {item.PRECIO1 ? item.PRECIO1.toFixed(2) : '0.00'}
                                        </Typography>
                                    </Grid>
                                    <Divider></Divider>
                                </Grid>
                            </ListItem>
                        )) :
                            <ListItem
                                button
                                onClick={() => { }}
                                key="Vacio"
                                alignItems='center'
                                sx={{ p: 0, pb: 1 }}
                            >
                                <Grid container
                                    justifyContent="center">
                                    <Grid item xs={8} sx={{ textAlign: 'center' }}>
                                        <Typography variant='h5' sx={{ color: red[100], m: 3 }}>Sin Facturas!</Typography>
                                    </Grid>
                                    <Divider></Divider>
                                </Grid>
                            </ListItem>
                        }
                    </List> */}
          <Card>
            {selectedBulkActions && (
              <Box flex={1} p={2}>
                <BulkActions />
              </Box>
            )}
            {!selectedBulkActions && (
              <CardHeader
                action={
                  <Box width={150}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={filters.status || 'all'}
                        onChange={handleStatusChange}
                        label="Status"
                        autoWidth
                      >
                        {statusOptions.map((statusOption) => (
                          <MenuItem
                            key={statusOption.id}
                            value={statusOption.id}
                          >
                            {statusOption.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                }
                title="Últimas Facturas"
              />
            )}
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {/*  <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={selectedAllCryptoOrders}
                                                indeterminate={selectedSomeCryptoOrders}
                                                onChange={handleSelectAllCryptoOrders}
                                            />
                                        </TableCell> */}
                    <TablecellHeaderDialog sx={{ textAlign: 'left' }}>
                      ID
                    </TablecellHeaderDialog>
                    <TablecellHeaderDialog>N° Fact.</TablecellHeaderDialog>
                    <TablecellHeaderDialog>Monto Base</TablecellHeaderDialog>
                    <TablecellHeaderDialog>Monto Total</TablecellHeaderDialog>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedCryptoOrders.map((cryptoOrder) => {
                    const isCryptoOrderSelected = selectedCryptoOrders.includes(
                      cryptoOrder.id
                    );
                    return (
                      <TableRow
                        hover
                        key={cryptoOrder.id}
                        selected={isCryptoOrderSelected}
                      >
                        {/*  <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isCryptoOrderSelected}
                                                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                                            handleSelectOneCryptoOrder(event, cryptoOrder.id)
                                                        }
                                                        value={isCryptoOrderSelected}
                                                    />
                                                </TableCell> */}
                        <TablecelDialog sx={{ textAlign: 'left' }}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {cryptoOrder.id}
                          </Typography>
                          {/*  <Typography variant="body2" color="text.secondary" noWrap>
                                                        {format(cryptoOrder.orderDate, 'MMMM dd yyyy')}
                                                    </Typography> */}
                        </TablecelDialog>
                        <TablecelDialog>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {cryptoOrder.orderID}
                          </Typography>
                        </TablecelDialog>
                        <TablecelDialog align="right">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {numeral(cryptoOrder.amount).format(`Bs 0,0.00`)}
                          </Typography>
                          {/*  <Typography variant="body2" color="text.secondary" noWrap>
                                                        {cryptoOrder.sourceDesc}
                                                    </Typography> */}
                        </TablecelDialog>
                        <TablecelDialog align="right">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {numeral(cryptoOrder.amount).format(`Bs 0,0.00`)}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            noWrap
                          >
                            {numeral(cryptoOrder.amount).format(
                              `${cryptoOrder.currency}0,0.00`
                            )}
                          </Typography>
                        </TablecelDialog>
                        {/* <TableCell align="right">
                                                    {getStatusLabel(cryptoOrder.status)}
                                                </TableCell>  
                                                 <TableCell align="right">
                                                    <Tooltip title="Edit Order" arrow>
                                                        <IconButton
                                                            sx={{
                                                                '&:hover': {
                                                                    background: theme.colors.primary.lighter
                                                                },
                                                                color: theme.palette.primary.main
                                                            }}
                                                            color="inherit"
                                                            size="small"
                                                        >
                                                            <EditTwoTone fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete Order" arrow>
                                                        <IconButton
                                                            sx={{
                                                                '&:hover': { background: theme.colors.error.lighter },
                                                                color: theme.palette.error.main
                                                            }}
                                                            color="inherit"
                                                            size="small"
                                                        >
                                                            <DeleteTwoTone fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={filteredCryptoOrders.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 30]}
              />
            </Box>
          </Card>
        </Container>
      </Dialog>

      {tipoDialogo === 'product-cliente' ? (
        <Promociones
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'product-cliente' ? (
        <ProductosQR
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo == 'exis' ? (
        <MiPerfil
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'changeExis' ? (
        <MisFacturas
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};
const MisPedidos = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [open1, setOpen1] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [dpto, setDpto] = useState();
  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };
  const fetDeositos = () => {
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/archivos/dpto`)
          .then((result) => {
            setDpto(result.data?.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };
  const fetchProductos = async (descr: string) => {
    if (inputValue && inputValue.length > 0) {
      try {
        descr = inputValue.toString() === '*' ? '' : descr;
        setLoading(true);
        const { Data, Messages } = await getProductosByDESCR(descr);
        setEncontrados(Data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching productos:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setEncontrados([]);
    }
  };
  const handleClose = () => {
    onClose();
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleListItemClick = (value) => {
    if (dpto) {
      setSelectedValue(value);
      setOpen1(true);
    } else {
      setState({
        ...state,
        openSb: true,
        message: 'Espere mientras cargan los datos...'
      });
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchProductos(inputValue);
    }
  };
  useEffect(() => {
    if (open) fetDeositos();
  }, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1,
              mb: 2
            }}
          >
            <TitleWrapper variant="h5">Mis Pedidos</TitleWrapper>
            <Button
              size="small"
              variant="contained"
              startIcon={<Add fontSize="small" />}
            >
              Agregar
            </Button>
          </Box>
        </DialogTitle>
        <Container maxWidth="sm" sx={{ p: 2 }}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInputWrapper
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleEnterKeyPress}
              placeholder="Buscar pedido por Número..."
              endAdornment={
                <InputAdornment position="end">
                  <ButtonSearch
                    variant="contained"
                    size="small"
                    onClick={() => fetchProductos(inputValue)}
                  >
                    Buscar
                  </ButtonSearch>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchTwoTone />
                </InputAdornment>
              }
            />
          </FormControl>
          {loading && <Spinner />}
          {encontrados?.length > 0 ? (
            <Divider
              sx={{ my: 2, color: theme.colors.error.main }}
            >{`${encontrados?.length} Encontrados (${filters})`}</Divider>
          ) : null}
          <List sx={{ pt: 0 }}>
            {encontrados?.length > 0 ? (
              encontrados.map((item: any) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(item)}
                  key={item.ID}
                  alignItems="center"
                  sx={{ p: 0, pb: 1 }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item xs={8} sx={{ textAlign: 'left' }}>
                      <Typography variant="body1">
                        {item.DESCR.trim()}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                      <Typography variant="h5">
                        $ {item.PRECIO1 ? item.PRECIO1.toFixed(2) : '0.00'}
                      </Typography>
                    </Grid>
                    <Divider></Divider>
                  </Grid>
                </ListItem>
              ))
            ) : (
              <ListItem
                button
                onClick={() => {}}
                key="Vacio"
                alignItems="center"
                sx={{ p: 0, pb: 1 }}
              >
                <Grid container justifyContent="center">
                  <Grid item xs={8} sx={{ textAlign: 'center' }}>
                    <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                      Sin Pedidos!
                    </Typography>
                  </Grid>
                  <Divider></Divider>
                </Grid>
              </ListItem>
            )}
          </List>
        </Container>
      </Dialog>

      {tipoDialogo === 'product-cliente' ? (
        <Promociones
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'product-cliente' ? (
        <ProductosQR
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo == 'exis' ? (
        <MiPerfil
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'changeExis' ? (
        <MisFacturas
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};
const MisCompras = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [open1, setOpen1] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [dpto, setDpto] = useState();
  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };
  const fetDeositos = () => {
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/archivos/dpto`)
          .then((result) => {
            setDpto(result.data?.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };
  const fetchProductos = async (descr: string) => {
    if (inputValue && inputValue.length > 0) {
      try {
        descr = inputValue.toString() === '*' ? '' : descr;
        setLoading(true);
        const { Data, Messages } = await getProductosByDESCR(descr);
        setEncontrados(Data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching productos:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setEncontrados([]);
    }
  };
  const handleClose = () => {
    onClose();
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleListItemClick = (value) => {
    if (dpto) {
      setSelectedValue(value);
      setOpen1(true);
    } else {
      setState({
        ...state,
        openSb: true,
        message: 'Espere mientras cargan los datos...'
      });
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchProductos(inputValue);
    }
  };
  useEffect(() => {
    if (open) fetDeositos();
  }, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1,
              mb: 2
            }}
          >
            <TitleWrapper variant="h5">Mis Compras</TitleWrapper>
          </Box>
        </DialogTitle>
        <Container maxWidth="sm" sx={{ p: 2 }}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInputWrapper
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleEnterKeyPress}
              placeholder="Buscar producto por Producto..."
              endAdornment={
                <InputAdornment position="end">
                  <ButtonSearch
                    variant="contained"
                    size="small"
                    onClick={() => fetchProductos(inputValue)}
                  >
                    Buscar
                  </ButtonSearch>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchTwoTone />
                </InputAdornment>
              }
            />
          </FormControl>
          {loading && <Spinner />}
          {encontrados?.length > 0 ? (
            <Divider
              sx={{ my: 2, color: theme.colors.error.main }}
            >{`${encontrados?.length} Encontrados (${filters})`}</Divider>
          ) : null}
          <List sx={{ p: 0, pt: 1 }}>
            {encontrados?.length > 0 ? (
              encontrados.map((item: any) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(item)}
                  key={item.ID}
                  alignItems="center"
                  sx={{ m: 0.5, pb: 1 }}
                >
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Grid container justifyContent="space-between">
                          <Grid item xs={8} sx={{ textAlign: 'left' }}>
                            <Typography variant="body1">
                              {item.DESCR.trim()}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <Typography variant="h5">
                              ${' '}
                              {item.PRECIO1 ? item.PRECIO1.toFixed(2) : '0.00'}
                            </Typography>
                          </Grid>
                          <Divider></Divider>
                        </Grid>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Grid container justifyContent="space-between">
                          <Grid item xs={6} sx={{ textAlign: 'left' }}>
                            <Typography variant="body2">
                              #Fact. {item.DESCR.trim()}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <Typography variant="body2">
                              Fecha: {item.DESCR.trim()}
                            </Typography>
                          </Grid>
                          <Divider></Divider>
                        </Grid>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <>
                <ListItem
                  button
                  key="2222"
                  alignItems="center"
                  sx={{ m: 1, pb: 1 }}
                >
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Grid container justifyContent="space-between">
                          <Grid item xs={8} sx={{ textAlign: 'left' }}>
                            <Typography variant="body1">
                              PRODUCTO PRUEBA
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <Typography variant="h5">$ 12.00</Typography>
                          </Grid>
                          <Divider></Divider>
                        </Grid>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Grid container justifyContent="space-between">
                          <Grid item xs={6} sx={{ textAlign: 'left' }}>
                            <Typography variant="body2">
                              #Fact. 1223321
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <Typography variant="body2">
                              Fecha: 01/01/2024
                            </Typography>
                          </Grid>
                          <Divider></Divider>
                        </Grid>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {}}
                  key="Vacio"
                  alignItems="center"
                  sx={{ p: 0, pb: 1 }}
                >
                  <Grid container justifyContent="center">
                    <Grid item xs={8} sx={{ textAlign: 'center' }}>
                      <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                        Sin Compras!
                      </Typography>
                    </Grid>
                    <Divider></Divider>
                  </Grid>
                </ListItem>
              </>
            )}
          </List>
        </Container>
      </Dialog>

      {tipoDialogo === 'product-cliente' ? (
        <Promociones
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'product-cliente' ? (
        <ProductosQR
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo == 'exis' ? (
        <MiPerfil
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : tipoDialogo === 'changeExis' ? (
        <MisFacturas
          dptos={dpto}
          selectedValue={selectedValue}
          open={open1}
          onClose={handleClose2}
        />
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};
const MiDelivery = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [open1, setOpen1] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [dpto, setDpto] = useState();
  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };
  const fetDeositos = () => {
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/archivos/dpto`)
          .then((result) => {
            setDpto(result.data?.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };
  const fetchProductos = async (descr: string) => {
    if (inputValue && inputValue.length > 0) {
      try {
        descr = inputValue.toString() === '*' ? '' : descr;
        setLoading(true);
        const { Data, Messages } = await getProductosByDESCR(descr);
        setEncontrados(Data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching productos:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setEncontrados([]);
    }
  };
  const handleClose = () => {
    onClose();
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleListItemClick = (value) => {
    if (dpto) {
      setSelectedValue(value);
      setOpen1(true);
    } else {
      setState({
        ...state,
        openSb: true,
        message: 'Espere mientras cargan los datos...'
      });
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchProductos(inputValue);
    }
  };
  useEffect(() => {
    if (open) fetDeositos();
  }, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1,
              mb: 2
            }}
          >
            <TitleWrapper variant="h5">Mis Compras</TitleWrapper>
          </Box>
        </DialogTitle>
        <Container maxWidth="sm" sx={{ p: 2 }}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInputWrapper
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleEnterKeyPress}
              placeholder="Buscar producto por Producto..."
              endAdornment={
                <InputAdornment position="end">
                  <ButtonSearch
                    variant="contained"
                    size="small"
                    onClick={() => fetchProductos(inputValue)}
                  >
                    Buscar
                  </ButtonSearch>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchTwoTone />
                </InputAdornment>
              }
            />
          </FormControl>
          {loading && <Spinner />}
          {encontrados?.length > 0 ? (
            <Divider
              sx={{ my: 2, color: theme.colors.error.main }}
            >{`${encontrados?.length} Encontrados (${filters})`}</Divider>
          ) : null}
          <List sx={{ p: 0, pt: 1 }}>
            {encontrados?.length > 0 ? (
              encontrados.map((item: any) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(item)}
                  key={item.ID}
                  alignItems="center"
                  sx={{ m: 0.5, pb: 1 }}
                >
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Grid container justifyContent="space-between">
                          <Grid item xs={8} sx={{ textAlign: 'left' }}>
                            <Typography variant="body1">
                              {item.DESCR.trim()}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <Typography variant="h5">
                              ${' '}
                              {item.PRECIO1 ? item.PRECIO1.toFixed(2) : '0.00'}
                            </Typography>
                          </Grid>
                          <Divider></Divider>
                        </Grid>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Grid container justifyContent="space-between">
                          <Grid item xs={6} sx={{ textAlign: 'left' }}>
                            <Typography variant="body2">
                              #Fact. {item.DESCR.trim()}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <Typography variant="body2">
                              Fecha: {item.DESCR.trim()}
                            </Typography>
                          </Grid>
                          <Divider></Divider>
                        </Grid>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <>
                <ListItem
                  button
                  key="2222"
                  alignItems="center"
                  sx={{ m: 1, pb: 1 }}
                >
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Grid container justifyContent="space-between">
                          <Grid item xs={8} sx={{ textAlign: 'left' }}>
                            <Typography variant="body1">
                              PRODUCTO PRUEBA
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <Typography variant="h5">$ 12.00</Typography>
                          </Grid>
                          <Divider></Divider>
                        </Grid>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Grid container justifyContent="space-between">
                          <Grid item xs={6} sx={{ textAlign: 'left' }}>
                            <Typography variant="body2">
                              #Fact. 1223321
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <Typography variant="body2">
                              Fecha: 01/01/2024
                            </Typography>
                          </Grid>
                          <Divider></Divider>
                        </Grid>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {}}
                  key="Vacio"
                  alignItems="center"
                  sx={{ p: 0, pb: 1 }}
                >
                  <Grid container justifyContent="center">
                    <Grid item xs={8} sx={{ textAlign: 'center' }}>
                      <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                        Sin Compras!
                      </Typography>
                    </Grid>
                    <Divider></Divider>
                  </Grid>
                </ListItem>
              </>
            )}
          </List>
        </Container>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};
const MisSugerencias = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [open1, setOpen1] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [dpto, setDpto] = useState();
  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };
  const fetDeositos = () => {
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/archivos/dpto`)
          .then((result) => {
            setDpto(result.data?.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };
  const fetchProductos = async (descr: string) => {
    if (inputValue && inputValue.length > 0) {
      try {
        descr = inputValue.toString() === '*' ? '' : descr;
        setLoading(true);
        const { Data, Messages } = await getProductosByDESCR(descr);
        setEncontrados(Data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching productos:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setEncontrados([]);
    }
  };
  const handleClose = () => {
    onClose();
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleListItemClick = (value) => {
    if (dpto) {
      setSelectedValue(value);
      setOpen1(true);
    } else {
      setState({
        ...state,
        openSb: true,
        message: 'Espere mientras cargan los datos...'
      });
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchProductos(inputValue);
    }
  };
  useEffect(() => {
    if (open) fetDeositos();
  }, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1,
              mb: 2
            }}
          >
            <TitleWrapper variant="h5">Mis Sugerencias</TitleWrapper>
            <Button
              size="small"
              variant="contained"
              startIcon={<Add fontSize="small" />}
            >
              Agregar
            </Button>
          </Box>
        </DialogTitle>
        <Container maxWidth="sm" sx={{ p: 2 }}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInputWrapper
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleEnterKeyPress}
              placeholder="Buscar sugerencia por texto..."
              endAdornment={
                <InputAdornment position="end">
                  <ButtonSearch
                    variant="contained"
                    size="small"
                    onClick={() => fetchProductos(inputValue)}
                  >
                    Buscar
                  </ButtonSearch>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchTwoTone />
                </InputAdornment>
              }
            />
          </FormControl>
          {loading && <Spinner />}
          {encontrados?.length > 0 ? (
            <Divider
              sx={{ my: 2, color: theme.colors.error.main }}
            >{`${encontrados?.length} Encontrados (${filters})`}</Divider>
          ) : null}
          <List sx={{ pt: 0 }}>
            {encontrados?.length > 0 ? (
              encontrados.map((item: any) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(item)}
                  key={item.ID}
                  alignItems="center"
                  sx={{ p: 0, pb: 1 }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item xs={8} sx={{ textAlign: 'left' }}>
                      <Typography variant="body1">
                        {item.DESCR.trim()}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                      <Typography variant="h5">
                        $ {item.PRECIO1 ? item.PRECIO1.toFixed(2) : '0.00'}
                      </Typography>
                    </Grid>
                    <Divider></Divider>
                  </Grid>
                </ListItem>
              ))
            ) : (
              <ListItem
                button
                onClick={() => {}}
                key="Vacio"
                alignItems="center"
                sx={{ p: 0, pb: 1 }}
              >
                <Grid container justifyContent="center">
                  <Grid item xs={8} sx={{ textAlign: 'center' }}>
                    <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                      Sin Sugerencias!
                    </Typography>
                  </Grid>
                  <Divider></Divider>
                </Grid>
              </ListItem>
            )}
          </List>
        </Container>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};
export {
  SimpleDialog,
  Promociones,
  MiPerfil,
  MisFacturas,
  ProductosQR,
  MisPedidos,
  MisSugerencias,
  MisCompras,
  MisDeseos,
  MiDelivery
};
