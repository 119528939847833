import { Box, Typography, Grid, useTheme, Divider } from '@mui/material';
import { format } from 'date-fns';
import { forwardRef, useState } from 'react';
import { ChoferesDelivery } from 'src/Data';

const DeliveryPrint = forwardRef((props: any, ref: any) => {
  const theme = useTheme();
  const formData = props.formData;

  return (
    <Box sx={{ display: 'block' }} ref={ref}>
      <Box sx={{ width: '100%', padding: '10px' }}>
        <Divider>*****************************</Divider>
        <Divider>
          <Typography variant="h4">
            REPORTE DELIVERY {format(Date.now(), 'dd/MM/yyyy HH:MM')}
          </Typography>
        </Divider>
        <Divider>*****************************</Divider>
      </Box>
      <Grid container spacing={2} sx={{ padding: theme.spacing(1) }}>
        <Grid item xs={4}>
          <Typography variant="h6">CHOFER</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            {' '}
            *** {
              ChoferesDelivery.find((c) => c.ci === formData.chofer)?.name
            }{' '}
            ***
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">ARTICULOS</Typography>
        </Grid>
        <Grid item xs={12}>
          {formData.articulos &&
            formData.articulos.map((a, idx) => (
              <Typography key={idx} variant="subtitle1">
                {' '}
                *** {a.DESCR + ' $' + a.PRECIO1?.toFixed(2)} ***
              </Typography>
            ))}
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6"> CEDULA </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            {' '}
            *** {formData.cedula} ***
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6"> TELEFONO </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            {' '}
            *** {formData.telefono} ***
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6"> CLIENTE </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            {' '}
            *** {formData.nombreApellido} ***
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">DIRECCION </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {' '}
            *** {formData.direccion} ***
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6">ZONA </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            {' '}
            *** {formData.zonaEntrega?.substring(0, 6)} ***
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6">TOTAL DELIVERY </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            {' '}
            *** {formData.precioDelivery?.toFixed(2)} ***
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6">TOTAL </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            {' '}
            *** {formData.total?.toFixed(2)} ***
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6">TOTAL PEDIDO </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            {' '}
            *** {formData.totalcondelivery?.toFixed(2)} ***
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6">VUELTO </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            {' '}
            ***{' '}
            {formData.vuelto !== undefined
              ? formData.vuelto.toFixed()
              : '0.00'}{' '}
            ***
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Divider sx={{ width: '100%', padding: '10px' }}>
          *****************************
        </Divider>
        <Divider sx={{ width: '100%', padding: '10px' }}>
          {' '}
          FIN REPORTE DELIVERY
        </Divider>
        <Divider sx={{ width: '100%', padding: '10px' }}>
          *****************************
        </Divider>
        <Typography variant="subtitle1">.</Typography>
      </Box>
    </Box>
  );
});

export { DeliveryPrint };
