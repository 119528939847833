import { useContext } from 'react';
import { Route, Navigate } from 'react-router';
import AuthContext from './contexts/AuthContext';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  if (isAuthenticated) {
    return children;
  }
  return <Navigate to="/login" />;
};

export { PrivateRoute };
