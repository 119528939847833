import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

const Spinner = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100px" // Ajusta la altura según tus necesidades
    >
      <Typography variant="h5" display={'inline-block'} textAlign={'center'}>
        Espere un momento...
      </Typography>
      <CircularProgress color="primary" />
    </Box>
  );
};

export default Spinner;
