const Permissions = {
  1: ['/'], //Administrador
  2: [
    '/inventario',
    '/admin',
    '/reportes',
    '/redessociales',
    '/dashboards/messenger'
  ], // supervidor
  3: ['/redessociales', '/dashboards/messenger'], //Redes Sociales
  4: ['/reportes'], //Choferes
  5: ['/clientes'], //Clientes
  6: ['/Reportes'] //Cosultas
};

const UsersData = [
  { user: 'Admin', password: '#admin', nivel: 1, permissions: Permissions[1] },
  {
    user: 'Supervisor',
    password: '#supervisor#',
    nivel: 2,
    permissions: Permissions[2]
  },
  { user: 'Redes', password: '?redes1', nivel: 3, permissions: Permissions[3] },
  {
    user: 'Chofer',
    password: '#12345#',
    nivel: 4,
    permissions: Permissions[4]
  },
  {
    user: 'Cliente',
    password: '$client',
    nivel: 5,
    permissions: Permissions[5]
  },
  {
    user: 'Consultas',
    password: 'consultas',
    nivel: 6,
    permissions: Permissions[6]
  },
  { user: 'Demo', password: 'demo1', nivel: 1, permissions: Permissions[1] }
];

const ZonasDelivery = [
  {
    name: 'Zona 1',
    value: 1,
    locations: ['Sabana Grande', 'Chacaito', 'Chacao', 'El Rosal']
  },
  {
    name: 'Zona 2',
    value: 2,
    locations: [
      'Estación capitolio',
      'Quinta Crespo',
      'Silencio',
      'Puente Hierro'
    ]
  },
  {
    name: 'Zona 3',
    value: 3,
    locations: [
      'Estación la hoyada',
      'Av libertador',
      'Av urdaneta',
      'Parque Central',
      'La candelaria',
      'Plaza Venezuela',
      'El Paraiso',
      'Catia',
      'Av fuerzas armadas',
      'San Bernardino',
      'Propatria-Cuartel',
      'Sabana grande',
      'Chacaito',
      'Chacao',
      'Cementerio - Av victoria',
      'La florida',
      'La Campiña',
      'Los Chaguaramos',
      'Av panteón',
      'Av San Martin',
      'La pastora',
      'Nva granada',
      'Av victoria',
      'Altavista (parte baja la ovejita)'
    ]
  },
  {
    name: 'Zona 4',
    value: 4,
    locations: [
      'EL Valle',
      'Coche',
      'Santa Mónica',
      'Bello Monte',
      'La Yaguara',
      'Las Mercedes',
      'Los Cortijos',
      'Los Ruices',
      'La California',
      'Av Rómulo Gallegos',
      'Montalban',
      'Los Proceres'
    ]
  },
  {
    name: 'Zona 5',
    value: 5,
    locations: [
      'Caricuao',
      'Alto Prado',
      'Macaracuay',
      'Vía Prados del Este',
      'Baruta Hatillo',
      'El Llanito',
      'Estacion Petare',
      'Santa Fé',
      'El Cafetal',
      'Terrazas del Avila, Los Naranjos',
      'Urbanización miranda',
      'Caurimare'
    ]
  }
];

const ChoferesDelivery = [
  { id: 1, name: 'Jesus Botero', telefono: '', ci: '123456' },
  { id: 2, name: 'Josen Flores', telefono: '', ci: '1234567' },
  { id: 3, name: 'Antonio Meza', telefono: '', ci: '1324567' },
  { id: 4, name: 'Anthony Villegas', telefono: '', ci: '187656' },
  { id: 5, name: 'Antonio Villegas', telefono: '', ci: '22134123' }
];

export { UsersData, ZonasDelivery, ChoferesDelivery };
