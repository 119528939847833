import axios from 'axios';

async function fetchWithFallback(
  primaryServerUrl: string,
  alternativeServerUrl: string
): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(primaryServerUrl);
      if (response.ok) {
        const data = await response.url;
        resolve(data);
      } else {
        throw new Error(
          `Error al consultar el servidor principal: ${response.statusText} ${primaryServerUrl}`
        );
      }
    } catch (error) {
      console.log(`Error al consultar el servidor principal: ${error.message}`);
      try {
        const response = await fetch(alternativeServerUrl);
        if (response.ok) {
          const data = await response.url;
          resolve(data);
        } else {
          throw new Error(
            `Error al consultar el servidor alternativo: ${response.statusText}`
          );
        }
      } catch (error) {
        console.log(
          `Error al consultar el servidor alternativo: ${error.message}`
        );
        reject(error);
      }
    }
  });
}

async function createAxiosInstance(): Promise<any> {
  try {
    const url = await fetchWithFallback(
      process.env.REACT_APP_API_SERVER_URL,
      process.env.REACT_APP_API_SERVER_URL_2
    );
    return axios.create({
      baseURL: url,
      timeout: 600000,
      headers: {
        Accept: 'application/json'
      }
    });
  } catch (error) {
    console.log(`Error al crear la instancia de Axios: ${error.message}`);
    throw error;
  }
}

export { createAxiosInstance };
