import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import { CardStyles } from 'src/components/styles';
import { Box } from '@mui/material';

export default function Card(props) {
  const useStyles = makeStyles(CardStyles);
  const classes = useStyles();
  const { className, children, plain, profile, chart, ...rest } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [className]: className !== undefined
  });
  return (
    <Box
      className={cardClasses}
      style={{ flexDirection: 'column', position: 'relative' }}
      {...rest}
    >
      {children}
    </Box>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  chart: PropTypes.bool,
  children: PropTypes.any
};
