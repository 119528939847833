import {
  Button,
  Grid,
  Box,
  CardContent,
  Typography,
  Tooltip,
  CardActionArea,
  useTheme
} from '@mui/material';
import {
  AccountBox,
  AutoStories,
  Build,
  ContentCopy,
  DeliveryDiningRounded,
  Filter9PlusRounded,
  Inventory2,
  Loop,
  Percent,
  PersonAdd,
  PlaylistAddCheckRounded,
  PlusOne,
  PostAdd,
  QrCode,
  ShoppingBasket
} from '@mui/icons-material';
import { useState } from 'react';
import {
  AvatarAddWrapper,
  BoardButtonsStyles,
  CardAddAction,
  CardAddActionArea,
  TitleWrapper
} from '../../overview/Panel/styles';
import {
  SimpleDialog,
  Promociones,
  MiPerfil,
  MisCompras,
  MisFacturas,
  MisPedidos,
  MisSugerencias,
  MisDeseos
} from '../../overview/Panel/Dialogs/Clientes';
import { cryptoOrders, products } from 'src/models/seed';
import MyCard from 'src/components/Card/Card';
import MyCardBody from 'src/components/Card/CardBody';
import MyCardFooter from 'src/components/Card/CardFooter';
import MyCardHeader from 'src/components/Card/CardHeader';
import MyCardIcon from 'src/components/Card/CardIcon';
import { DashboardStyle } from 'src/components/styles';
import { makeStyles } from '@mui/styles';
import { TipoDialogo } from 'src/utils/Helpers';
import { SimpleDialogUsuario } from '../../overview/Panel/Dialogs/Admin';

function Cliente() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [tipoDialogo, setTipoDialogo] = useState<TipoDialogo>(TipoDialogo.None);
  const handleClickOpen = (value: TipoDialogo) => {
    switch (value) {
      case TipoDialogo.AjusteExistencia:
        setOpen(true);
        break;
      case TipoDialogo.Existencia:
        break;
      case TipoDialogo.Productos:
        break;
      case TipoDialogo.cambioPrecios:
        break;
      case TipoDialogo.None:
        break;

      default:
        break;
    }
    setTipoDialogo(value);
  };
  const handleClose = (value) => {
    setTipoDialogo(TipoDialogo.None);
  };
  const classes = makeStyles(DashboardStyle)();
  const cStyle = makeStyles(BoardButtonsStyles)();
  return (
    <>
      <MyCard>
        <MyCardHeader color="danger">
          <h4 className={classes.cardTitleWhite}>Clientes</h4>
          <p className={classes.cardCategoryWhite}>
            Administración y visualización de Clientes
          </p>
        </MyCardHeader>
        <MyCardBody className={cStyle.cardBodyPpal}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip
                arrow
                title="Click Acceder a lista de Productos y existencias"
              >
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.clienteProductos)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <QrCode />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Productos
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Consulta de Productos y Existencia
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click ver Puntos acumulados por Compra">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.clientePuntos)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <Filter9PlusRounded />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Puntos Acumulados
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Puntos acumulados por Compras
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click ver Promociones y Ofertas">
                <CardAddAction
                  onClick={() =>
                    handleClickOpen(TipoDialogo.clientePromociones)
                  }
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <Percent />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Promociones
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Consulta nuestras Promociones
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click ver mi Perfil">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.clientePerfil)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <AccountBox />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Mi Perfil
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Información de Cliente
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click ver Mis Facturas">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.clienteFacturas)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <ContentCopy />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Mis Facturas
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Realación de Facturas
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click ver y Agregar Pedidos">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.clientePedidos)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <PostAdd />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Mis Pedidos
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Relación de Pedidos
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click ver Mis Compras">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.clienteCompras)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <ShoppingBasket />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Mis Compras
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Relación de Compras
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click ver Status de su Delivery">
                <CardAddAction
                  onClick={() =>
                    handleClickOpen(TipoDialogo.MantenimientoSistema)
                  }
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <DeliveryDiningRounded />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Mi Delivery
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Visualice el estado de su Delivery
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click Agregar Sugerencias">
                <CardAddAction
                  onClick={() =>
                    handleClickOpen(TipoDialogo.MantenimientoSistema)
                  }
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <AutoStories />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Mis Sugerencias
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Haga y visualice sus mensajes y comentarios
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
          </Grid>
        </MyCardBody>
      </MyCard>
      {tipoDialogo === TipoDialogo.Usuarios && (
        <SimpleDialogUsuario
          tipoDialogo={tipoDialogo}
          open={tipoDialogo === TipoDialogo.Usuarios}
          onClose={handleClose}
        />
      )}
      {tipoDialogo === TipoDialogo.clienteMisdeseos && (
        <MisDeseos
          items={products}
          open={tipoDialogo === TipoDialogo.clienteMisdeseos}
          onClose={handleClose}
        />
      )}
      {tipoDialogo === TipoDialogo.clientePromociones && (
        <Promociones
          open={tipoDialogo === TipoDialogo.clientePromociones}
          onClose={handleClose}
        />
      )}
      {tipoDialogo === TipoDialogo.clientePerfil && (
        <MiPerfil
          open={tipoDialogo === TipoDialogo.clientePerfil}
          onClose={handleClose}
        />
      )}
      {tipoDialogo === TipoDialogo.clienteFacturas && (
        <MisFacturas
          cryptoOrders={cryptoOrders}
          open={tipoDialogo === TipoDialogo.clienteFacturas}
          onClose={handleClose}
        />
      )}
      {tipoDialogo === TipoDialogo.clientePedidos && (
        <MisPedidos
          open={tipoDialogo === TipoDialogo.clientePedidos}
          onClose={handleClose}
        />
      )}
      {tipoDialogo === TipoDialogo.clienteCompras && (
        <MisCompras
          open={tipoDialogo === TipoDialogo.clienteCompras}
          onClose={handleClose}
        />
      )}
      {tipoDialogo === TipoDialogo.clienteSugerencias && (
        <MisSugerencias
          open={tipoDialogo === TipoDialogo.clienteSugerencias}
          onClose={handleClose}
        />
      )}
    </>
  );
}

export default Cliente;
