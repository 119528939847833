import {
  Grid,
  Typography,
  useTheme,
  Dialog,
  List,
  ListItem,
  Container,
  FormControl,
  Divider,
  InputAdornment,
  Snackbar,
  DialogContent,
  Alert
} from '@mui/material';
import { SearchTwoTone } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { red } from '@mui/material/colors';
import Spinner from 'src/components/spinner';
import {
  ProductosEdit,
  Existencia,
  AjusteExistencia,
  CambioPrecios
} from './Inventario';
import {
  ButtonSearch,
  OutlinedInputWrapper,
  StyledDialogPaper
} from '../styles';
import { BuscraCliente, TipoDialogo } from 'src/utils/Helpers';
import MyCard from 'src/components/Card/Card';
import MyCardBody from 'src/components/Card/CardBody';
import MyCardHeader from 'src/components/Card/CardHeader';
import { DashboardStyle } from 'src/components/styles';
import { makeStyles } from '@mui/styles';
import {
  getClientes,
  getDatosCNE,
  getDepositos,
  getProductosByDESCR
} from 'src/services/services';
import { Clientes } from './Admin';
import { boolean } from 'yup';

const SimpleDIalog = () => {
  return <></>;
};

const SimpleDialogProducto = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo, stocks } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [tipoDialogo2, setTipoDialogo2] = useState<TipoDialogo>(
    TipoDialogo.None
  );
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [dpto, setDpto] = useState();

  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };
  const fetchDepositos = async () => {
    try {
      setLoading(true);
      const { Data }: any = await getDepositos();
      if (Data) {
        setDpto(Data);
      }
    } catch (error) {
      console.log('Error fetching Depositos:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchProductos = async (descr: string) => {
    if (inputValue && inputValue.length > 0) {
      try {
        descr = inputValue.toString() === '*' ? '' : descr;
        setLoading(true);
        const { Data, Messages } = await getProductosByDESCR(
          descr,
          tipoDialogo === TipoDialogo.redesExistencia
        );
        setEncontrados(Data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching productos:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setEncontrados([]);
    }
  };
  const handleClose = () => {
    if (onClose) onClose();
  };
  const handleClose2 = () => {
    setTipoDialogo2(TipoDialogo.None);
  };
  const handleListItemClick = (value) => {
    if (dpto) {
      /* switch (tipoDialogo) {
        case TipoDialogo.AjusteExistencia:
          break;
        case TipoDialogo.Existencia:
          break;
        case TipoDialogo.Productos:
          break;
        case TipoDialogo.cambioPrecios:
          break;
        default:
          break;
      } */
      setSelectedValue(value);
      setTipoDialogo2(tipoDialogo);
    } else {
      setState({
        ...state,
        openSb: true,
        message: 'Espere mientras cargan los depositos...'
      });
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchProductos(inputValue);
    }
  };
  const classes = makeStyles(DashboardStyle)();
  useEffect(() => {
    if (open) fetchDepositos();
  }, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperComponent={StyledDialogPaper}
      >
        <DialogContent sx={{ p: 0, m: 0 }}>
          <MyCard>
            <MyCardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Seleccionar Producto</h4>
              <p className={classes.cardCategoryWhite}>
                {encontrados?.length > 0
                  ? `${encontrados?.length} Productos (${filters})`
                  : null}
              </p>
            </MyCardHeader>
            <MyCardBody>
              <Container
                maxWidth="sm"
                sx={{ maxHeight: '60vh', p: '.5 !important', overflow: 'auto' }}
              >
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInputWrapper
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleEnterKeyPress}
                    placeholder="Buscar producto por nombre..."
                    endAdornment={
                      <InputAdornment position="end">
                        <ButtonSearch
                          variant="contained"
                          size="small"
                          onClick={() => fetchProductos(inputValue)}
                        >
                          Buscar
                        </ButtonSearch>
                      </InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchTwoTone />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {loading && <Spinner />}
                <List sx={{ pt: 0 }}>
                  {encontrados ? (
                    encontrados.map((item) => (
                      <ListItem
                        button
                        onClick={() => handleListItemClick(item)}
                        key={item.ID}
                        alignItems="center"
                        sx={{ p: 0, pb: 1 }}
                      >
                        <Grid container justifyContent="space-between">
                          <Grid item xs={4} sx={{ textAlign: 'center' }}>
                            <img
                              src={
                                item.IMG?.includes('http')
                                  ? item.IMG
                                  : 'https://res.cloudinary.com/kalixto-systems-solutions/image/upload/v1726134900/rpc_view/so5l4il5xfqqkbw9ykg0.png'
                              }
                              alt={item.DESCR?.slice(0, 10)}
                              width="50"
                              height="50"
                            />
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: 'left' }}>
                            <Typography variant="body1">
                              {item.DESCR.trim()}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sx={{ textAlign: 'right' }}>
                            <Typography variant="h5">
                              $ {item.PRECIO1.toFixed(2) ?? '0.00'}
                            </Typography>
                          </Grid>
                          <Divider></Divider>
                        </Grid>
                      </ListItem>
                    ))
                  ) : (
                    <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                      Producto no encontrado!
                    </Typography>
                  )}
                </List>
              </Container>
            </MyCardBody>
          </MyCard>
        </DialogContent>
      </Dialog>
      {tipoDialogo2 === TipoDialogo.Productos && (
        <ProductosEdit
          dptos={dpto}
          selectedValue={selectedValue}
          open={tipoDialogo2 === TipoDialogo.Productos}
          onClose={handleClose2}
        />
      )}
      {(tipoDialogo2 === TipoDialogo.Existencia ||
        tipoDialogo2 === TipoDialogo.redesExistencia) && (
        <Existencia
          dptos={dpto}
          selectedValue={selectedValue}
          open={
            tipoDialogo2 === TipoDialogo.Existencia ||
            tipoDialogo2 === TipoDialogo.redesExistencia
          }
          onClose={handleClose2}
        />
      )}
      {tipoDialogo2 === TipoDialogo.AjusteExistencia && (
        <AjusteExistencia
          dptos={dpto}
          selectedValue={selectedValue}
          open={tipoDialogo2 === TipoDialogo.AjusteExistencia}
          onClose={handleClose2}
        />
      )}
      {tipoDialogo2 === TipoDialogo.cambioPrecios && (
        <CambioPrecios
          dptos={dpto}
          selectedValue={selectedValue}
          open={tipoDialogo2 === TipoDialogo.cambioPrecios}
          onClose={handleClose2}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};
SimpleDialogProducto.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tipoDialogo: TipoDialogo,
  stocks: boolean
  /* tipoDialogo: PropTypes.oneOf(['exis', 'producto', 'changeExis']).isRequired, */
};

const SimpleDialogCliente = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo, message } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [tipoDialogo2, setTipoDialogo2] = useState<TipoDialogo>(
    TipoDialogo.None
  );
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetItems = async (descr: string) => {
    try {
      setLoading(true);
      if (inputValue && inputValue.length > 0) {
        descr = inputValue.toString() === '*' ? '' : descr;
        const data = await BuscraCliente(descr, tipoDialogo);
        setEncontrados(data);
      } else {
        setEncontrados([]);
      }
    } catch (error) {
      console.log('Error fetching clientes:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (value) => {
    onClose(true);
  };
  const handleClose2 = (value) => {
    setTipoDialogo2(TipoDialogo.None);
  };
  const handleListItemClick = (value) => {
    setSelectedValue(value);
    setTipoDialogo2(tipoDialogo);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetItems(inputValue);
    }
  };
  const classes = makeStyles(DashboardStyle)();
  useEffect(() => {}, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperComponent={StyledDialogPaper}
      >
        <DialogContent sx={{ p: 0, m: 0 }}>
          <MyCard>
            <MyCardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Seleccionar Cliente</h4>
              {message && <Alert severity="info">{message}</Alert>}
              <p className={classes.cardCategoryWhite}>
                {encontrados?.length > 0
                  ? `${encontrados?.length} Clientes (${filters})`
                  : null}
              </p>
            </MyCardHeader>
            <MyCardBody>
              <Container
                maxWidth="sm"
                sx={{ maxHeight: '60vh', p: '.5 !important', overflow: 'auto' }}
              >
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInputWrapper
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleEnterKeyPress}
                    placeholder="Buscar producto por nombre..."
                    endAdornment={
                      <InputAdornment position="end">
                        <ButtonSearch
                          variant="contained"
                          size="small"
                          onClick={() => fetItems(inputValue)}
                        >
                          Buscar
                        </ButtonSearch>
                      </InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchTwoTone />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {loading && <Spinner />}
                <List sx={{ pt: 0 }}>
                  {encontrados ? (
                    encontrados.map((item, idx) => (
                      <ListItem
                        button
                        onClick={() => handleListItemClick(item)}
                        key={idx}
                        alignItems="center"
                        sx={{ p: 0, pb: 1 }}
                      >
                        <Grid container justifyContent="space-between">
                          <Grid item xs={8} sx={{ textAlign: 'left' }}>
                            <Typography variant="body1">
                              {item.NOMBRE.trim()}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <Typography variant="h5">{item.RIF}</Typography>
                          </Grid>
                          <Divider></Divider>
                        </Grid>
                      </ListItem>
                    ))
                  ) : (
                    <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                      Cliente no encontrado!
                    </Typography>
                  )}
                </List>
              </Container>
            </MyCardBody>
          </MyCard>
        </DialogContent>
      </Dialog>
      <Clientes
        selectedValue={selectedValue}
        open={
          tipoDialogo2 === TipoDialogo.Clientes ||
          tipoDialogo2 === TipoDialogo.redesDatosCliente
        }
        onClose={handleClose2}
      />
    </>
  );
};

SimpleDialogCliente.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tipoDialogo: TipoDialogo,
  message: TipoDialogo

  /* tipoDialogo: PropTypes.oneOf(['exis', 'producto', 'changeExis']).isRequired, */
};

export { SimpleDialogProducto, SimpleDIalog, SimpleDialogCliente };
