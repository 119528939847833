export type TipoExistencia =
  | 'Agotados'
  | 'Disponible'
  | 'Bloqueado'
  | 'En Transito';

export enum TipoDialogo {
  None,
  cambioPrecios,
  Productos,
  Existencia,
  AjusteExistencia,
  Clientes,
  Usuarios,
  Pedidos,
  VentasXCaja,
  VentasDiarias,
  AnalisiProducto,
  MantenimientoSistema,
  listaProductos,
  catalogoProductos,
  clienteProductos,
  clienteMisdeseos,
  clientePuntos,
  clientePromociones,
  clientePerfil,
  clienteFacturas,
  clientePedidos,
  clienteCompras,
  clienteSugerencias,
  redesExistencia,
  redesPostInstagramIA,
  redesEnvioDelivery,
  redesChatBotWhatsapp,
  redesDatosCliente
}
export function esSoloNumeros(valor: any): boolean {
  return typeof valor === 'number' && Number.isInteger(valor);
}
import { getClientes, getDatosCNE } from 'src/services/services';
import tinycolor from 'tinycolor2';

export function calcularDiferenciaDias(diaIni, diaFin) {
  debugger;
  const diferenciaEnMilisegundos = diaFin - diaIni;

  const segundos = Math.floor(diferenciaEnMilisegundos / 1000);
  const minutos = Math.floor(segundos / 60);
  const horas = Math.floor(minutos / 60);
  const dias = Math.floor(horas / 24);
  const result = {
    segundos: segundos,
    minutos: minutos,
    horas: horas,
    dias: dias
  };
  return result;
}

export function calcularDiferenciaFechas(fechaIni, fechaActual) {
  const milisegundosPorSegundo = 1000;
  const milisegundosPorMinuto = milisegundosPorSegundo * 60;
  const milisegundosPorHora = milisegundosPorMinuto * 60;
  const milisegundosPorDia = milisegundosPorHora * 24;

  // Parsear las fechas
  const fechaInicial = new Date(fechaIni);
  const fechaActualDate = new Date(fechaActual);

  // Calcular la diferencia en milisegundos
  let diferencia = Math.abs(fechaActual - fechaIni);

  // Calcular días, horas y minutos
  const dias = Math.floor(diferencia / milisegundosPorDia);
  diferencia %= milisegundosPorDia;

  const horas = Math.floor(diferencia / milisegundosPorHora);
  diferencia %= milisegundosPorHora;

  const minutos = Math.floor(diferencia / milisegundosPorMinuto);
  diferencia %= milisegundosPorMinuto;

  const segundos = Math.floor(diferencia / milisegundosPorSegundo);

  const result = {
    segundos: segundos,
    minutos: minutos,
    horas: horas - 4, //TODO:Le quito 4 Horas por Venezuela
    dias: dias
  };
  return result;
}
export function sumarHoraAFecha(fechaString, horaString) {
  // Parsear la fecha
  const fecha = new Date(fechaString);

  // Parsear la hora
  const [hora, minutos, periodo] = horaString
    .match(/(\d+):(\d+) ([APMapm]{2})/)
    .slice(1, 4);
  let horas = parseInt(hora, 10);

  // Ajustar la hora según el periodo (AM/PM)
  if (periodo.toLowerCase() === 'pm' && horas < 12) {
    horas += 12;
  } else if (periodo.toLowerCase() === 'am' && horas === 12) {
    horas = 0;
  }

  // Sumar la hora y los minutos a la fecha
  fecha.setHours(
    fecha.getHours() + horas,
    fecha.getMinutes() + parseInt(minutos, 10)
  );
  return fecha;
}
export function generarColorAleatorio(length) {
  const hue = Math.floor(Math.random() * (360 - length));
  const baseColor = tinycolor({ h: hue, s: 0.8, l: 0.5 }); // HSL color
  return baseColor.toHexString();
}

export const hexToRgb = (input) => {
  input = input + '';
  input = input.replace('#', '');
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error('input is not a valid hex color.');
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase();
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ', ' +
    parseInt(second, 16) +
    ', ' +
    parseInt(last, 16)
  );
};

export const formatNumberMoneda = (number, esVe = true) => {
  if (number)
    if (esVe) {
      return number?.toLocaleString('es-VE', {
        style: 'currency',
        currency: 'VES',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    } else {
      return number?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
};

export const fechaFormateada = (fecha?: any) => {
  const date = new Date().toLocaleDateString('es-ES', {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  });
  if (fecha !== undefined) {
    return new Date(fecha).toLocaleDateString('es-ES', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });
  } else {
    return date;
  }
};
export const BuscraCliente = async (descr, tipoDialogo) => {
  const obtenerCampo = (
    descr: string
  ): { RIF: string } | { NOMBRE: string } | { TELEFONO: string } => {
    if (/^\d+$/.test(descr)) {
      if (descr.startsWith('04') && descr.length === 11)
        return { TELEFONO: `%${descr}%` };
      else return { RIF: `%${descr}%` };
    } else {
      return { NOMBRE: `%${descr.toUpperCase()}%` };
    }
  };
  const attributes = ['ID', 'DIRECCION', 'CODIGO', 'NOMBRE', 'RIF', 'TELEFONO'];
  const { Data }: any = await getClientes({
    data: obtenerCampo(descr),
    options: { attributes }
  });
  try {
    if (tipoDialogo === TipoDialogo.redesDatosCliente)
      if (Data && Data[0]) {
        const { Data: DataCNE }: any = await getDatosCNE(Data[0].RIF);
        Data[0].CNE = DataCNE;
      } else {
        if (/^\d+$/.test(descr)) {
          const data = [];
          const { Data: DataCNE }: any = await getDatosCNE(parseInt(descr));
          if (DataCNE)
            data.push({
              NOMBRE: DataCNE.name,
              RIF: DataCNE.rif,
              CNE: { ...DataCNE }
            });
          return data;
        }
      }
    return Data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
