import { CryptoOrder, Products } from './types';
import { subDays } from 'date-fns';

const cryptoOrders: CryptoOrder[] = [
  {
    id: '1',
    orderDetails: 'Fiat Deposit',
    orderDate: new Date().getTime(),
    status: 'completed',
    orderID: '78940990',
    sourceName: 'Bank Account',
    sourceDesc: '*** 1111',
    amountCrypto: 34.4565,
    amount: 56787,
    cryptoCurrency: 'ETH',
    currency: '$'
  },
  {
    id: '2',
    orderDetails: 'Fiat Deposit',
    orderDate: subDays(new Date(), 1).getTime(),
    status: 'completed',
    orderID: '23451678',
    sourceName: 'Bank Account',
    sourceDesc: '*** 1111',
    amountCrypto: 6.58454334,
    amount: 8734587,
    cryptoCurrency: 'BTC',
    currency: '$'
  },
  {
    id: '3',
    orderDetails: 'Fiat Deposit',
    orderDate: subDays(new Date(), 5).getTime(),
    status: 'failed',
    orderID: '23453672',
    sourceName: 'Bank Account',
    sourceDesc: '*** 1111',
    amountCrypto: 6.58454334,
    amount: 8734587,
    cryptoCurrency: 'BTC',
    currency: '$'
  },
  {
    id: '4',
    orderDetails: 'Fiat Deposit',
    orderDate: subDays(new Date(), 55).getTime(),
    status: 'completed',
    orderID: '95768293',
    sourceName: 'Bank Account',
    sourceDesc: '*** 1111',
    amountCrypto: 6.58454334,
    amount: 8734587,
    cryptoCurrency: 'BTC',
    currency: '$'
  },
  {
    id: '5',
    orderDetails: 'Fiat Deposit',
    orderDate: subDays(new Date(), 56).getTime(),
    status: 'pending',
    orderID: '10293844',
    sourceName: 'Bank Account',
    sourceDesc: '*** 1111',
    amountCrypto: 6.58454334,
    amount: 8734587,
    cryptoCurrency: 'BTC',
    currency: '$'
  },
  {
    id: '6',
    orderDetails: 'Fiat Deposit',
    orderDate: subDays(new Date(), 33).getTime(),
    status: 'completed',
    orderID: '18738892',
    sourceName: 'Bank Account',
    sourceDesc: '*** 1111',
    amountCrypto: 6.58454334,
    amount: 8734587,
    cryptoCurrency: 'BTC',
    currency: '$'
  },
  {
    id: '7',
    orderDetails: 'Fiat Deposit',
    orderDate: new Date().getTime(),
    status: 'pending',
    orderID: '28374659',
    sourceName: 'Bank Account',
    sourceDesc: '*** 1212',
    amountCrypto: 2.346546,
    amount: 234234,
    cryptoCurrency: 'BTC',
    currency: '$'
  },
  {
    id: '8',
    orderDetails: 'Paypal Withdraw',
    orderDate: subDays(new Date(), 22).getTime(),
    status: 'completed',
    orderID: '19192828',
    sourceName: 'Paypal Account',
    sourceDesc: '*** 1111',
    amountCrypto: 3.345456,
    amount: 34544,
    cryptoCurrency: 'BTC',
    currency: '$'
  },
  {
    id: '9',
    orderDetails: 'Fiat Deposit',
    orderDate: subDays(new Date(), 11).getTime(),
    status: 'completed',
    orderID: '375647481',
    sourceName: 'Bank Account',
    sourceDesc: '*** 2222',
    amountCrypto: 1.4389567945,
    amount: 123843,
    cryptoCurrency: 'BTC',
    currency: '$'
  },
  {
    id: '10',
    orderDetails: 'Wallet Transfer',
    orderDate: subDays(new Date(), 123).getTime(),
    status: 'failed',
    orderID: '29102929',
    sourceName: 'Wallet Transfer',
    sourceDesc: "John's Cardano Wallet",
    amountCrypto: 765.5695,
    amount: 7567,
    cryptoCurrency: 'ADA',
    currency: '$'
  }
];

const products: Products[] = [
  {
    id: '1',
    status: 'unselected',
    desc: 'El iPhone 14 Pro Max. Pantalla de 6,7 pulgadas. A16 Bionic. Cámara triple. Batería de larga duración.',
    precio: 1.199,
    precio2: 1.099
  },
  {
    id: '2',
    status: 'unselected',
    desc: 'El Samsung Galaxy S23 Ultra. Pantalla de 6,8 pulgadas. Snapdragon 8 Gen 2. Cámara cuádruple. Batería de larga duración.',
    precio: 1.199,
    precio2: 1.099
  },
  {
    id: '3',
    status: 'unselected',
    desc: 'El MacBook Pro de 14 pulgadas. Pantalla de 14 pulgadas. M2 Pro. GPU de 10 núcleos. Batería de larga duración.',
    precio: 1.999,
    precio2: 1.899
  },
  {
    id: '4',
    status: 'unselected',
    desc: 'La tableta iPad Pro de 12,9 pulgadas. Pantalla de 12,9 pulgadas. M2 Pro. GPU de 10 núcleos. Batería de larga duración.',
    precio: 1.099,
    precio2: 999
  },
  {
    id: '5',
    status: 'unselected',
    desc: 'La consola de videojuegos PlayStation 5. AMD Zen 2. AMD RDNA 2. Unidad de estado sólido de 825 GB. Juegos de PS4.',
    precio: 499,
    precio2: 449
  },
  {
    id: '6',
    status: 'unselected',
    desc: 'La consola de videojuegos Xbox Series X. AMD Zen 2. AMD RDNA 2. Unidad de estado sólido de 1 TB. Juegos de Xbox One.',
    precio: 499,
    precio2: 449
  },
  {
    id: '7',
    status: 'unselected',
    desc: 'El monitor gaming ASUS ROG Swift PG27UQ. Pantalla de 27 pulgadas. Resolución 4K. Frecuencia de actualización de 144 Hz. Sincronización adaptativa.',
    precio: 1.499,
    precio2: 1.399
  },
  {
    id: '8',
    status: 'unselected',
    desc: 'La computadora portátil para juegos Dell Alienware m17 R5. Pantalla de 17 pulgadas. Intel Core i7-12700H. NVIDIA GeForce RTX 3080 Ti. Memoria RAM de 32 GB.',
    precio: 3.999,
    precio2: 3.699
  },
  {
    id: '9',
    status: 'unselected',
    desc: 'La impresora 3D Creality Ender-3 V2. Impresora 3D FDM de nivel de entrada. Buena relación calidad-precio.',
    precio: 299,
    precio2: 279
  },
  {
    id: '10',
    status: 'unselected',
    desc: 'El altavoz inteligente Amazon Echo Dot. Altavoz Bluetooth con Alexa. Control por voz.',
    precio: 29.99,
    precio2: 24.99
  }
];
export { cryptoOrders, products };
