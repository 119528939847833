import {
  Button,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Avatar,
  alpha,
  Tooltip,
  CardActionArea,
  styled,
  useTheme
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import {
  AttachMoney,
  Inventory2,
  Loop,
  PlusOne,
  QrCode,
  Summarize
} from '@mui/icons-material';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
          margin: ${theme.spacing(2, 0, 1, -0.5)};
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: ${theme.spacing(1)};
          padding: ${theme.spacing(0.5)};
          border-radius: 60px;
          height: ${theme.spacing(5.5)};
          width: ${theme.spacing(5.5)};
          background: ${
            theme.palette.mode === 'dark'
              ? theme.colors.alpha.trueWhite[30]
              : alpha(theme.colors.alpha.black[100], 0.07)
          };
        
          img {
            background: ${theme.colors.alpha.trueWhite[100]};
            padding: ${theme.spacing(0.5)};
            display: block;
            border-radius: inherit;
            height: ${theme.spacing(4.5)};
            width: ${theme.spacing(4.5)};
          }
      `
);

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
              background: ${theme.colors.alpha.black[10]};
              color: ${theme.colors.error.main}; 
              width: ${theme.spacing(8)};
              height: ${theme.spacing(8)};
      `
);
const TitleWrapper = styled(Typography)(
  ({ theme }) => `
              color: ${theme.colors.alpha.white[100]};  
      `
);
const CardAddAction = styled(Card)(
  ({ theme }) => `
              border: ${theme.colors.primary.main} dashed 1px;
              height: 100%;
              color: ${theme.colors.primary.main};
              transition: ${theme.transitions.create(['all'])};
              
              .MuiCardActionArea-root {
                height: 100%;
                justify-content: center;
                align-items: center;
                display: flex;
              }
              
              .MuiTouchRipple-root {
                opacity: .2;
              }
              
              &:hover {
                border-color: ${theme.colors.alpha.black[70]};
              }
      `
);

function Reporte() {
  const theme = useTheme();
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          background: theme.colors.error.main,
          p: 1,
          mb: 2
        }}
      >
        <TitleWrapper variant="h3">Reportes</TitleWrapper>
        {/*  <Button
            size="small"
            variant="contained"
            startIcon={<Loop fontSize="small" />}
          >
            Cambiar Precio
          </Button> */}
      </Box>
      <Grid container spacing={3}>
        <Grid xs={12} sm={6} md={3} item>
          <Tooltip arrow title="Click Reporte lista de Productos">
            <CardAddAction>
              <CardActionArea
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  px: 1
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      px: 1
                    }}
                  >
                    <AvatarAddWrapper>
                      <Summarize fontSize="large" />
                    </AvatarAddWrapper>
                  </Box>
                  <Box
                    sx={{
                      pt: 3
                    }}
                  >
                    <Typography variant="h4" gutterBottom noWrap>
                      Lista de Precios
                    </Typography>
                    <Typography variant="body2">
                      Lista Precios por Producto
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </CardAddAction>
          </Tooltip>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Tooltip arrow title="Click Reporte Existencia">
            <CardAddAction>
              <CardActionArea
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  px: 1
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      px: 1
                    }}
                  >
                    <AvatarAddWrapper>
                      <Summarize fontSize="large" />
                    </AvatarAddWrapper>
                  </Box>
                  <Box
                    sx={{
                      pt: 3
                    }}
                  >
                    <Typography variant="h4" gutterBottom noWrap>
                      Existencia
                    </Typography>
                    <Typography variant="body2">
                      Relación Existencia x Producto
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </CardAddAction>
          </Tooltip>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Tooltip arrow title="Click Reporte Ventas Diarias">
            <CardAddAction>
              <CardActionArea
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  px: 1
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      px: 1
                    }}
                  >
                    <AvatarAddWrapper>
                      <Summarize fontSize="large" />
                    </AvatarAddWrapper>
                  </Box>
                  <Box
                    sx={{
                      pt: 3
                    }}
                  >
                    <Typography variant="h4" gutterBottom noWrap>
                      Ventas Diarias
                    </Typography>
                    <Typography variant="body2">
                      Relación de Ventas Diarias
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </CardAddAction>
          </Tooltip>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Tooltip arrow title="Click Reporte Ventas Diarias">
            <CardAddAction>
              <CardActionArea
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  px: 1
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      px: 1
                    }}
                  >
                    <AvatarAddWrapper>
                      <Summarize fontSize="large" />
                    </AvatarAddWrapper>
                  </Box>
                  <Box
                    sx={{
                      pt: 3
                    }}
                  >
                    <Typography variant="h4" gutterBottom noWrap>
                      Ventas x Producto
                    </Typography>
                    <Typography variant="body2">
                      Relación Ventas x Producto
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </CardAddAction>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}

export default Reporte;
