import {
  DialogContent,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  useTheme
} from '@mui/material';
import { Close, EditTwoTone } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  Box,
  Grid,
  Divider,
  Container,
  Card,
  Typography,
  CardContent,
  Button
} from '@mui/material';
import { PointOfSale, SearchTwoTone } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { red } from '@mui/material/colors';
import Spinner from 'src/components/spinner';
import {
  ButtonSearch,
  OutlinedInputWrapper,
  TitleWrapper,
  ListItemAvatarWrapper,
  StyledDialogPaper
} from '../styles';
import {
  TipoDialogo,
  calcularDiferenciaFechas,
  generarColorAleatorio,
  sumarHoraAFecha
} from 'src/utils/Helpers';
import Text from 'src/components/Text';
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { createAxiosInstance } from 'src/api';
import MyCard from 'src/components/Card/Card';
import MyCardBody from 'src/components/Card/CardBody';
import MyCardHeader from 'src/components/Card/CardHeader';
import { DashboardStyle } from 'src/components/styles';
import { makeStyles } from '@mui/styles';
import { getClientes, getDatosCNE } from 'src/services/services';

//Styles

//Inventario
const Productos = (props) => {
  const theme = useTheme();
  const { onClose, selectedValue, open, dptos } = props;
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose(true);
  };
  const fetchStocks = (codigo: string) => {
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/productos/stock`, {
          data: {
            CODIGO: `${codigo.trim()}`
          }
        })
          .then((result) => {
            const rawData = result.data?.data;
            if (rawData && Array.isArray(rawData) && rawData.length > 0) {
              const updatedData = rawData.map((item: any) => ({
                ...item,
                DPTO: dptos?.find((x) => x.CODIGO === item.DPTO)?.DESCR || '--'
              }));
              setStocks(updatedData);
            } else {
              setStocks([]);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        // Manejar cualquier error que ocurra al obtener la instancia de Axios
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };

  useEffect(() => {
    if (selectedValue && open) {
      fetchStocks(selectedValue?.CODIGO);
    }
  }, [open]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: theme.colors.error.main,
            p: 1
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <TitleWrapper variant="h5">Detalle de Producto</TitleWrapper>
            </Grid>
            <Divider></Divider>
          </Grid>
        </Box>
      </DialogTitle>
      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Box>
          <Grid item xs={12}>
            <Card>
              <Box p={2} alignItems="center" justifyContent="space-between">
                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                  EXISTENCIA
                </Typography>
                <Box width={'100%'}>
                  {loading && <Spinner />}
                  {!loading && stocks ? (
                    stocks.map((stock, index) => (
                      <Grid
                        container
                        key={stock.DPTO + index}
                        justifyContent="space-between"
                      >
                        <Grid
                          item
                          xs={8}
                          sm={8}
                          md={8}
                          sx={{ textAlign: 'left' }}
                        >
                          <Typography variant="body1">
                            {stock?.DPTO?.trim()}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          sx={{ textAlign: 'right' }}
                        >
                          <Typography variant="h5">
                            {stock ? stock.ENT - stock.SAL : 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                      Producto no encontrado!
                    </Typography>
                  )}
                </Box>
              </Box>
              <Divider />
              <CardContent sx={{ p: 2 }}>
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      textAlign={{ sm: 'right' }}
                    >
                      <Box pr={3} pb={2}>
                        Nombre:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <Text color="black">
                        <b>{selectedValue?.DESCR?.trim()}</b>
                      </Text>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      textAlign={{ sm: 'right' }}
                    >
                      <Box pr={3} pb={2}>
                        Precio Bs.:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <Text color="black">
                        <b>
                          {selectedValue?.PRECIO
                            ? selectedValue.PRECIO.toFixed(2)
                            : '0.00'}
                        </b>
                      </Text>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      textAlign={{ sm: 'right' }}
                    >
                      <Box pr={3} pb={2}>
                        Precio $:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                        <Text color="black">
                          <b>
                            {selectedValue?.PRECIO1
                              ? selectedValue.PRECIO1.toFixed(2)
                              : '0.00'}
                          </b>
                        </Text>
                      </Box>
                    </Grid>
                  </Grid>
                </Typography>
                <Box
                  p={1}
                  alignItems="center"
                  textAlign={'end'}
                  justifyContent="space-between"
                  width={'100%'}
                  sx={{
                    background: theme.colors.primary.lighter,
                    p: 1
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditTwoTone />}
                  >
                    Editar
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};
const Usuarios = (props) => {
  const theme = useTheme();
  const { onClose, selectedValue, open, dptos } = props;
  const [stocks, setStocks] = useState([]);
  const handleClose = () => {
    onClose(true);
  };

  useEffect(() => {}, [open]);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: theme.colors.error.main,
            p: 1
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <TitleWrapper variant="h5">Detalle de Usuario</TitleWrapper>
            </Grid>
            <Divider></Divider>
          </Grid>
        </Box>
      </DialogTitle>
      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Box>
          <Grid item xs={12}>
            <Card>
              <Divider />
              <CardContent sx={{ p: 2 }}>
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid item xs={4} sm={3} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Nombre:
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9}>
                      <Text color="black">
                        <b>{selectedValue?.DESCR?.trim()}</b>
                      </Text>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        CARGO:
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9}>
                      <Text color="black">
                        <b>{selectedValue?.CARGO_DESCR?.DESCR?.trim() ?? ''}</b>
                      </Text>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        NIVEL:
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9}>
                      <Text color="black">
                        <b>{selectedValue?.CARGO_DESCR?.NIVEL ?? ''}</b>
                      </Text>
                    </Grid>
                  </Grid>
                </Typography>
                <Box
                  p={1}
                  alignItems="center"
                  textAlign={'end'}
                  justifyContent="end"
                  width={'100%'}
                  sx={{
                    background: theme.colors.primary.lighter,
                    p: 1
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditTwoTone />}
                  >
                    Editar
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};
const Clientes = (props) => {
  const theme = useTheme();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(true);
  };

  useEffect(() => {}, [open]);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: theme.colors.error.main,
            p: 1
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <TitleWrapper variant="h5">Detalle de Cliente</TitleWrapper>
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Box>
          <Grid item xs={12}>
            <Card>
              <Divider />
              <CardContent sx={{ p: 2 }}>
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid item xs={4} sm={3} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Nombre:
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9}>
                      <Text color="black">
                        <b>{selectedValue?.NOMBRE?.trim()}</b>
                      </Text>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        RIF:
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9}>
                      <Text color="black">
                        <b>{selectedValue?.RIF?.trim()}</b>
                      </Text>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Telefono:
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9}>
                      <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                        <Text color="black">
                          <b>{selectedValue?.TELEFONO}</b>
                        </Text>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={4} pb={2}>
                        Dirección:
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9}>
                      <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                        <Text color="black">
                          <b>{selectedValue?.DIRECCION}</b>
                        </Text>
                      </Box>
                    </Grid>
                  </Grid>
                </Typography>
                <Divider />
                {selectedValue?.CNE && (
                  <Box sx={{ p: 2, width: '100%' }}>
                    <Typography gutterBottom variant="body2" fontWeight={'600'}>
                      Otros Datos:
                    </Typography>
                    <Typography variant="subtitle2">
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12}>
                          {selectedValue?.CNE.name?.trim()}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          {selectedValue?.CNE.rif?.trim()}
                        </Grid>
                        <Grid xs={12} sm={12} md={12}>
                          {selectedValue?.CNE.direccion}
                        </Grid>
                        <Grid xs={12} sm={12} md={12}>
                          <b>
                            {' '}
                            {selectedValue?.CNE.municipality},{' '}
                            {selectedValue?.CNE.parish},{' '}
                            {selectedValue?.CNE.state}
                          </b>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Box>
                )}
                <Box
                  p={1}
                  alignItems="center"
                  textAlign={'end'}
                  justifyContent="space-between"
                  width={'100%'}
                  sx={{
                    background: theme.colors.primary.lighter,
                    p: 1
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Close />}
                    onClick={handleClose}
                  >
                    Cerrar
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};
const Pedidos = (props) => {
  const theme = useTheme();
  const { onClose, selectedValue, open, dptos } = props;
  const [stocks, setStocks] = useState([]);
  const handleClose = () => {
    onClose(true);
  };

  useEffect(() => {}, [open]);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: theme.colors.error.main,
            p: 1
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <TitleWrapper variant="h5">Pedidos Actuales</TitleWrapper>
            </Grid>
            <Divider></Divider>
          </Grid>
        </Box>
      </DialogTitle>
      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Box>
          <Grid item xs={12}>
            <Card>
              <Divider />
              <CardContent sx={{ p: 2 }}>
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid item xs={4} sm={3} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Nombre:
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9}>
                      <Text color="black">
                        <b>{selectedValue?.NOMBRE?.trim()}</b>
                      </Text>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        RIF:
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9}>
                      <Text color="black">
                        <b>{selectedValue?.RIF?.trim()}</b>
                      </Text>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Telefono:
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9}>
                      <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                        <Text color="black">
                          <b>{selectedValue?.TELEFONO}</b>
                        </Text>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={4} pb={2}>
                        Dirección:
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9}>
                      <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                        <Text color="black">
                          <b>{selectedValue?.DIRECCION}</b>
                        </Text>
                      </Box>
                    </Grid>
                  </Grid>
                </Typography>
                <Box
                  p={1}
                  alignItems="center"
                  textAlign={'end'}
                  justifyContent="space-between"
                  width={'100%'}
                  sx={{
                    background: theme.colors.primary.lighter,
                    p: 1
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditTwoTone />}
                  >
                    Editar
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};
const SimpleDialogUsuario = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [tipoDialogo2, setTipoDialogo2] = useState<TipoDialogo>(
    TipoDialogo.None
  );
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [cmbx, setCmbx] = useState([]);
  const fetcmbx = () => {
    setLoading(true);

    createAxiosInstance()
      .then((API) => {
        API.post(`/api/cmbxcargos`)
          .then((result) => {
            setCmbx(result.data?.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };
  const fetItems = (descr: string) => {
    if (inputValue && inputValue.length > 0) {
      descr = inputValue.toString() === '*' ? '' : descr;
      setLoading(true);
      const obtenerCampo = (
        descr: string
      ): { RIF: string } | { DESCR: string } => {
        if (/^\d+$/.test(descr)) {
          return { RIF: `%${descr}%` };
        } else {
          return { DESCR: `%${descr.toUpperCase()}%` };
        }
      };
      createAxiosInstance()
        .then((API) => {
          API.post(`/api/cmbxusuarios`, {
            data: obtenerCampo(descr)
          })
            .then((result) => {
              const data = result.data?.data;
              if (data) {
                let datatmp = [];
                data.forEach((x) => {
                  const CARGO_DESCR = cmbx.find((y) => y.CODIGO === x.CARGO);
                  datatmp.push({
                    ...x,
                    CARGO_DESCR
                  });
                });
                setEncontrados(datatmp);
              } else setEncontrados([]);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch((error) => {
          console.error('Error al obtener la instancia de Axios:', error);
        });
    } else {
      setEncontrados([]);
    }
  };
  const handleClose = () => {
    onClose();
  };
  const handleClose2 = () => {
    setTipoDialogo2(TipoDialogo.None);
  };
  const handleListItemClick = (value) => {
    setSelectedValue(value);
    setTipoDialogo2(tipoDialogo);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetItems(inputValue);
    }
  };
  const classes = makeStyles(DashboardStyle)();
  useEffect(() => {
    if (open) fetcmbx();
  }, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperComponent={StyledDialogPaper}
      >
        <DialogContent sx={{ p: 0, m: 0 }}>
          <MyCard>
            <MyCardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Seleccionar Usuario</h4>
              <p className={classes.cardCategoryWhite}>
                {encontrados?.length > 0
                  ? `${encontrados?.length} Usuarios (${filters})`
                  : null}
              </p>
            </MyCardHeader>
            <MyCardBody>
              <Container
                maxWidth="sm"
                sx={{ maxHeight: '60vh', p: '.5 !important', overflow: 'auto' }}
              >
                {loading && <Spinner />}
                {!loading && (
                  <Box flex={1}>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInputWrapper
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleEnterKeyPress}
                        placeholder="Buscar producto por nombre..."
                        endAdornment={
                          <InputAdornment position="end">
                            <ButtonSearch
                              variant="contained"
                              size="small"
                              onClick={() => fetItems(inputValue)}
                            >
                              Buscar
                            </ButtonSearch>
                          </InputAdornment>
                        }
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchTwoTone />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    {loading && <Spinner />}
                    <List sx={{ pt: 0 }}>
                      {encontrados ? (
                        encontrados.map((item) => (
                          <ListItem
                            button
                            onClick={() => handleListItemClick(item)}
                            key={item.ID}
                            alignItems="center"
                            sx={{ p: 0, pb: 1 }}
                          >
                            <Grid container justifyContent="space-between">
                              <Grid item xs={8} sx={{ textAlign: 'left' }}>
                                <Typography variant="body1">
                                  {item?.DESCR?.trim()}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <Typography variant="h5">
                                  {item?.CARGO_DESCR?.DESCR?.trim() ?? ''}
                                </Typography>
                              </Grid>
                              <Divider></Divider>
                            </Grid>
                          </ListItem>
                        ))
                      ) : (
                        <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                          Usuario no encontrado!
                        </Typography>
                      )}
                    </List>
                  </Box>
                )}
              </Container>
            </MyCardBody>
          </MyCard>
        </DialogContent>
      </Dialog>
      <Usuarios
        selectedValue={selectedValue}
        open={tipoDialogo2 === TipoDialogo.Usuarios}
        onClose={handleClose2}
      />
    </>
  );
};
const SimpleDialogPedidos = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [tipoDialogo2, setTipoDialogo2] = useState<TipoDialogo>(
    TipoDialogo.None
  );
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [cmbx, setCmbx] = useState([]);

  const fetchItems = () => {
    setLoading(true);
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/cmbxidentifi`, {
          options: {
            order: [
              ['FECHA', 'DESC'],
              ['HORAINI', 'DESC']
            ],
            include: ['Identifi_Details']
          }
        })
          .then((result) => {
            const data = result.data?.data;
            setEncontrados(data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };
  const handleClose = () => {
    onClose();
  };
  const handleClose2 = () => {
    setTipoDialogo2(TipoDialogo.None);
  };
  const handleListItemClick = (value) => {
    setSelectedValue(value);
    setTipoDialogo2(tipoDialogo);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      setFilters(inputValue);
    }
  };
  const TiempoTranscurrido = (fechaIni, horaIni) => {
    let dates;
    if (fechaIni && horaIni) {
      dates = sumarHoraAFecha(fechaIni, horaIni);
    } else dates = new Date();
    const result = calcularDiferenciaFechas(dates, new Date());
    let resultado = '';
    if (result.dias > 0)
      resultado += `${result.dias}D${result.dias > 1 ? 's' : ''} `;
    if (result.horas > 0)
      resultado += `${result.horas}H${result.horas > 1 ? 's' : ''} `;
    if (result.minutos > 0)
      resultado += `${result.minutos}M${result.minutos > 1 ? 's' : ''} `;
    if (result.segundos > 0)
      resultado += `${result.segundos}S${result.segundos > 1 ? 's' : ''}`;
    return resultado;
  };
  const classes = makeStyles(DashboardStyle)();
  useEffect(() => {
    if (open) fetchItems();
  }, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperComponent={StyledDialogPaper}
      >
        <DialogContent sx={{ p: 0, m: 0 }}>
          <MyCard>
            <MyCardHeader color="primary">
              <h4 className={classes.cardTitleWhite}> Lista de Pedidos</h4>
              <p className={classes.cardCategoryWhite}>
                {encontrados?.length > 0
                  ? `${encontrados?.length} Pedidos (${filters})`
                  : null}
              </p>
            </MyCardHeader>
            <MyCardBody>
              <Container
                maxWidth="sm"
                sx={{ maxHeight: '60vh', p: '.5 !important', overflow: 'auto' }}
              >
                {loading && <Spinner />}
                {!loading && (
                  <Box flex={1}>
                    <List sx={{ pt: 0 }}>
                      {encontrados ? (
                        encontrados.map((item) => (
                          <>
                            <ListItem
                              button
                              onClick={() => handleListItemClick(item)}
                              key={item.ID}
                              alignItems="center"
                              sx={{ p: 0, pb: 1 }}
                            >
                              <Grid container justifyContent="space-between">
                                <Grid item xs={8} sx={{ textAlign: 'left' }}>
                                  <Typography variant="h5">
                                    {item?.RIF?.trim()}
                                  </Typography>
                                  <Typography variant="body2">
                                    {item?.NOMCLI?.trim()}
                                  </Typography>
                                  <Typography variant="caption">
                                    Items:{item?.Identifi_Details?.length ?? 0}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                  <Typography variant="body1">
                                    {TiempoTranscurrido(
                                      item?.FECHA,
                                      item?.HORAINI
                                    )}
                                  </Typography>
                                  <Typography variant="h5">
                                    {item?.MONTO?.toFixed(2) ?? ''}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ListItem>
                            <Divider
                              sx={{ mb: 1, color: theme.colors.error.main }}
                            />
                          </>
                        ))
                      ) : (
                        <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                          Pedido no encontrado!
                        </Typography>
                      )}
                    </List>
                  </Box>
                )}
              </Container>
            </MyCardBody>
          </MyCard>
        </DialogContent>
      </Dialog>
      <Usuarios
        selectedValue={selectedValue}
        open={tipoDialogo2 === TipoDialogo.Usuarios}
        onClose={handleClose2}
      />
    </>
  );
};
const SimpleDialogVentasXCaja = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [encontrados, setEncontrados] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [tipoDialogo2, setTipoDialogo2] = useState<TipoDialogo>(
    TipoDialogo.None
  );
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [cmbx, setCmbx] = useState([]);
  const [labels, setLabels] = useState([]);
  const [percents, setPercents] = useState([]);
  const [colors, setColors] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchItems = () => {
    setLoading(true);
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/admin/ventasxcaja`)
          .then((result) => {
            const data = result.data?.data;
            if (data) {
              const totalVentas = data.reduce(
                (total, factura) => total + factura.TOTAL,
                0
              );
              // Calcular el porcentaje para cada caja
              const facturasConPorcentaje = data.map((factura, index) => ({
                ...factura,
                PERCENT: ((factura.TOTAL / totalVentas) * 100).toFixed(2),
                COLOR: generarColorAleatorio(data.length)
              }));
              const percents = facturasConPorcentaje.map((f) =>
                parseFloat(f.PERCENT)
              );
              const labels = facturasConPorcentaje.map((f) => f.NOMBRE?.trim());
              const xcolors = facturasConPorcentaje.map((f) => f.COLOR);
              setTotal(totalVentas);
              setPercents(percents);
              setLabels(labels);
              setColors(xcolors);
              setEncontrados(facturasConPorcentaje);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          size: '50%'
        }
      }
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '%';
      },
      style: {
        colors: [theme.colors.alpha.trueWhite[100]]
      },
      background: {
        enabled: true,
        foreColor: theme.colors.alpha.trueWhite[100],
        padding: 8,
        borderRadius: 4,
        borderWidth: 0,
        opacity: 0.3,
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          color: theme.colors.alpha.black[70],
          opacity: 0.5
        }
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: theme.colors.alpha.black[50],
        opacity: 0.5
      }
    },
    fill: {
      opacity: 1
    },
    labels: labels,
    legend: {
      labels: {
        colors: theme.colors.alpha.trueWhite[100]
      },
      show: false
    },
    stroke: {
      width: 0
    },
    theme: {
      mode: theme.palette.mode
    }
  };
  const handleClose = () => {
    setTotal(0);
    setPercents([]);
    setLabels([]);
    setColors([]);
    setEncontrados([]);
    onClose();
  };
  const handleClose2 = () => {
    setTipoDialogo2(TipoDialogo.None);
  };
  const classes = makeStyles(DashboardStyle)();
  const fechaFormateada = (fecha) =>
    fecha
      ? new Date(fecha).toLocaleDateString('es-ES', {
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        })
      : '';
  useEffect(() => {
    if (open) fetchItems();
  }, [open]);

  useEffect(() => {
    setFilters(inputValue);
  }, [encontrados]);
  return (
    <>
      <Dialog
        fullWidth
        onClose={handleClose}
        open={open}
        PaperComponent={StyledDialogPaper}
      >
        <DialogContent sx={{ p: 0, m: 0 }}>
          <MyCard>
            <MyCardHeader color="primary">
              <h4 className={classes.cardTitleWhite}> Ventas X Caja</h4>
              <p className={classes.cardCategoryWhite}>
                Total: {total ? total.toFixed(2) : '0.00'}
              </p>
            </MyCardHeader>
            <MyCardBody>
              <Container
                maxWidth="sm"
                sx={{ maxHeight: '60vh', p: '.5 !important', overflow: 'auto' }}
              >
                {loading && <Spinner />}
                {!loading && (
                  <Box flex={1}>
                    <Grid container spacing={0}>
                      <Grid
                        xs={12}
                        sm={12}
                        item
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Chart
                          height={250}
                          options={chartOptions}
                          series={percents}
                          type="donut"
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        item
                        display="flex"
                        alignItems="center"
                      >
                        <List
                          disablePadding
                          sx={{
                            width: '100%'
                          }}
                        >
                          {encontrados ? (
                            encontrados.map((item, index) => (
                              <>
                                <ListItem disableGutters key={index}>
                                  <ListItemAvatarWrapper
                                    sx={{ background: item.COLOR ?? 'red' }}
                                  >
                                    <PointOfSale sx={{ color: 'white' }} />
                                  </ListItemAvatarWrapper>
                                  <ListItemText
                                    primary={`${item?.NOMBRE?.trim()} (${fechaFormateada(
                                      item?.FECHA
                                    )})`}
                                    primaryTypographyProps={{
                                      variant: 'h5',
                                      noWrap: true
                                    }}
                                    secondary={'Facturas:' + item?.ITEMS ?? '0'}
                                    secondaryTypographyProps={{
                                      variant: 'subtitle2',
                                      noWrap: true
                                    }}
                                  />
                                  <Box>
                                    <Typography
                                      align="right"
                                      variant="h4"
                                      noWrap
                                    >
                                      {item?.PERCENT ?? '0'}%
                                    </Typography>
                                    <Text color="success">
                                      {item?.TOTAL?.toFixed(2) ?? '0.00'}
                                    </Text>
                                  </Box>
                                </ListItem>
                                <Divider
                                  sx={{ mb: 1, color: theme.colors.error.main }}
                                />
                              </>
                            ))
                          ) : (
                            <Typography
                              variant="h5"
                              sx={{ color: red[100], m: 3 }}
                            >
                              Sin CAJAS!
                            </Typography>
                          )}
                        </List>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Container>
            </MyCardBody>
          </MyCard>
        </DialogContent>
      </Dialog>
      <Usuarios
        selectedValue={selectedValue}
        open={tipoDialogo2 === TipoDialogo.Usuarios}
        onClose={handleClose2}
      />
    </>
  );
};
export {
  Productos,
  Pedidos,
  Clientes,
  Usuarios,
  SimpleDialogUsuario,
  SimpleDialogPedidos,
  SimpleDialogVentasXCaja
};
