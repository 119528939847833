import { Grid, Box, Typography, Tooltip, useTheme } from '@mui/material';
import { AutoAwesomeMotion, Favorite, Inventory2, Loop, PhotoLibrary, PlusOne, QrCode, ViewList } from '@mui/icons-material';
import { useState } from 'react';
import {
  BoardButtonsStyles,
  CardAddAction,
  CardAddActionArea
} from '../../overview/Panel/styles';
import { SimpleDialogProducto } from '../../overview/Panel/Dialogs';
import { TipoDialogo } from 'src/utils/Helpers';
import { CambioPrecios, CatalogoProductos, ListaProductos } from '../../overview/Panel/Dialogs/Inventario';
import MyCard from 'src/components/Card/Card';
import MyCardBody from 'src/components/Card/CardBody';
import MyCardFooter from 'src/components/Card/CardFooter';
import MyCardHeader from 'src/components/Card/CardHeader';
import MyCardIcon from 'src/components/Card/CardIcon';
import { DashboardStyle } from 'src/components/styles';
import { makeStyles } from '@mui/styles';

function Inventario() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [tipoDialogo, setTipoDialogo] = useState<TipoDialogo>(TipoDialogo.None);

  const handleClickOpen = (value: TipoDialogo) => {
    switch (value) {
      case TipoDialogo.AjusteExistencia:
        setOpen(true);
        break;
      case TipoDialogo.Existencia:
        break;
      case TipoDialogo.Productos:
        break;
      case TipoDialogo.cambioPrecios:
        break;
      case TipoDialogo.None:
        break;

      default:
        break;
    }
    setTipoDialogo(value);
  };

  const handleClose = (value) => {
    setTipoDialogo(TipoDialogo.None);
  };

  const classes = makeStyles(DashboardStyle)();
  const cStyle = makeStyles(BoardButtonsStyles)();
  return (
    <>
      <MyCard>
        <MyCardHeader color="danger">
          <h4 className={classes.cardTitleWhite}>Inventario</h4>
          <p className={classes.cardCategoryWhite}>
            Control de Productos y existencias
          </p>
        </MyCardHeader>
        <MyCardBody className={cStyle.cardBodyPpal}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={6} md={3} item>
              <Tooltip arrow title="Click Consultar Productos y Existencias">
                {/* <CardAddAction onClick={() => handleClickOpen(TipoDialogo.Productos)}> */}
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.Productos)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="error" stats icon>
                        <MyCardIcon color="primary">
                          <QrCode />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Productos
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Consultar Productos
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              <Tooltip arrow title="Click Acceder a Existencias">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.Existencia)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="error" stats icon>
                        <MyCardIcon color="primary">
                          <Inventory2 />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Existencias
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Consultar Existencias
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              <Tooltip arrow title="Click Ajustar existencias actuales">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.AjusteExistencia)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="error" stats icon>
                        <MyCardIcon color="primary">
                          <PlusOne />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Ajuste Existencia
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Ajustar Existencias
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              <Tooltip arrow title="Click Cambio Masivo de Precios">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.cambioPrecios)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="error" stats icon>
                        <MyCardIcon color="primary">
                          <Loop />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Cambio Precios
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Cambiar Precios
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              <Tooltip arrow title="Click Lista de Productos">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.listaProductos)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="error" stats icon>
                        <MyCardIcon color="primary">
                          <ViewList />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Lista de Productos
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Mostrar todos los productos
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              <Tooltip arrow title="Click Catálogo de Productos">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.catalogoProductos)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="error" stats icon>
                        <MyCardIcon color="primary">
                          <PhotoLibrary />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Catálogo de Productos
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Catálogo de productos
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
          </Grid>
        </MyCardBody>
      </MyCard>
      {tipoDialogo === TipoDialogo.Productos && (
        <SimpleDialogProducto
          tipoDialogo={tipoDialogo}
          open={tipoDialogo === TipoDialogo.Productos}
          onClose={handleClose}
          stocks={false}
        />
      )}
      {tipoDialogo === TipoDialogo.Existencia && (
        <SimpleDialogProducto
          tipoDialogo={tipoDialogo}
          open={tipoDialogo === TipoDialogo.Existencia}
          onClose={handleClose}
          stocks={false}
        />
      )}
      {tipoDialogo === TipoDialogo.AjusteExistencia && (
        <SimpleDialogProducto
          tipoDialogo={tipoDialogo}
          open={tipoDialogo === TipoDialogo.AjusteExistencia}
          onClose={handleClose}
          stocks={false}
        />
      )}
      {tipoDialogo === TipoDialogo.cambioPrecios && (
        <CambioPrecios
          tipoDialogo={tipoDialogo}
          open={tipoDialogo === TipoDialogo.cambioPrecios}
          onClose={handleClose}
        />
      )}
      {tipoDialogo === TipoDialogo.listaProductos && (
        <ListaProductos
          tipoDialogo={tipoDialogo}
          open={tipoDialogo === TipoDialogo.listaProductos}
          onClose={handleClose}
        />
      )}
      {tipoDialogo === TipoDialogo.catalogoProductos && (
        <CatalogoProductos
          tipoDialogo={tipoDialogo}
          open={tipoDialogo === TipoDialogo.catalogoProductos}
          onClose={handleClose}
        />
      )}
    </>
  );
}

export default Inventario;
