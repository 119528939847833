import axios from 'axios';
import { createAxiosInstance } from 'src/api';
import { Response } from 'src/models/models';

/**
 *
 * @param find
 * @returns
 */
const getMonedas = async (find: string): Promise<Response> => {
  try {
    const data = { CODIGO: find.trim().toUpperCase() };
    const API = await createAxiosInstance();
    const { Data, Status, Messages, Exception } = await API.post(
      '/api/archivos/monedas',
      { data }
    ).then((i) => i.data);
    return { Data, Status, Messages, Exception };
  } catch (error) {
    return {
      Data: null,
      Status: 500,
      Messages: 'error al ejecutar getstocks!',
      Exception: error.message
    };
  }
};
/**
 * Obtener estock de Productos
 * @param id  ID de Producto
 * @param dpto Codigo de Deposito
 * @param periodo  Año de Consulta
 * @returns
 */
const getStocks = async (
  find: string,
  dpto?: string,
  periodo?: string
): Promise<Response> => {
  try {
    const data = { CODIGO: find.trim().toUpperCase() };
    const API = await createAxiosInstance();
    const { Data, Status, Messages, Exception } = await API.post(
      '/api/productos/stock',
      { data }
    ).then((i) => i.data);
    return { Data, Status, Messages, Exception };
  } catch (error) {
    return {
      Data: null,
      Status: 500,
      Messages: 'error al ejecutar getstocks!',
      Exception: error.message
    };
  }
};

const setSTocks = (options, value) => {
  try {
  } catch (error) {}
};
/**
 * Obtener Usuarios
 * @param id de usuario
 * @returns
 */
const getUsers = async (find?: string): Promise<Response> => {
  try {
    const data = find ? { CODIGO: find.trim().toUpperCase() } : undefined;
    const API = await createAxiosInstance();
    const { Data, Status, Messages, Exception } = await API.post(
      '/usuarios/get',
      { data }
    ).then((i) => i.data);
    return new Response(Status, Data, Messages, Exception);
  } catch (error) {
    return new Response(500, null, 'Fail getUser', error);
  }
};

/**
 * Obtener depositos
 * @param id Numero de deposito
 * @returns
 */
const getDepositos = async (find?: string): Promise<Response> => {
  try {
    const data = find ? { id: find.trim().toUpperCase() } : undefined;
    const options = { attributes: ['CODIGO', 'DESCR', 'ID'] };
    const API = await createAxiosInstance();
    const { Data, Status, Messages, Exception } = await API.post(
      '/api/archivos/dpto',
      { data, options }
    ).then((i) => i.data);
    return new Response(Status, Data, Messages, Exception);
  } catch (error) {
    return new Response(500, null, 'Fail getDepositos', error);
  }
};

/**
 * Obtener prroductos por Descripcion
 * @param find Descipcion del Producto
 * @param id
 * @param descr
 * @returns
 */
const getProductosByDESCR = async (
  find?: string,
  stocks?: boolean,
  id?: number,
  descr?: string
): Promise<Response> => {
  try {
    let condicion: any = {};
    const attributes = [
      'ID',
      'DESCR',
      'PRECIO',
      'PRECIO1',
      'PRECIO2',
      'PRECIO3',
      'CODIGO',
      'PRECIOM',
      'PRECIO1M',
      'PRECIO2M',
      'BARRA'
    ]; // Campos que deseas obtener
    const data = { DESCR: `%${find?.trim().toUpperCase()}%` };
    const include = stocks ? ['stocks'] : undefined;
    const options = {
      attributes,
      where: condicion,
      include
    };
    const API = await createAxiosInstance();
    const { Data, Status, Messages, Exception } = await API.post(
      `/api/productos/list`,
      { data, options }
    ).then((i) => i.data);
    //const result = Data.map(data => ({ DESCR: data.DESCR.trim(), CODIGO: data.CODIGO.trim(), PRECIO: data.PRECIO, PRECIO1: data.PRECIO1, PRECIO3: data.PRECIO2, PRECIOM: data.PRECIOM, PRECIO1M: data.PRECIO1M, PRECIO2M: data.PRECIO2M, EXIS: data.EXIS }))
    return new Response(Status, Data, Messages, Exception);
  } catch (error) {
    return new Response(500, null, 'Fail getProductosByDESCR', error);
  }
};
const getProductos = async (stocks?: boolean): Promise<Response> => {
  try {
    let condicion: any = {};
    const attributes = [
      'ID',
      'DESCR',
      'PRECIO',
      'PRECIO1',
      'PRECIO2',
      'PRECIO3',
      'CODIGO',
      'PRECIOM',
      'PRECIO1M',
      'PRECIO2M',
      'BARRA'
    ]; // Campos que deseas obtener
    const include = stocks ? ['stocks'] : undefined;
    const options = {
      attributes,
      where: condicion,
      include
    };
    const API = await createAxiosInstance();
    const { Data, Status, Messages, Exception } = await API.post(
      `/api/productos/list`,
      { options }
    ).then((i) => i.data);
    //const result = Data.map(data => ({ DESCR: data.DESCR.trim(), CODIGO: data.CODIGO.trim(), PRECIO: data.PRECIO, PRECIO1: data.PRECIO1, PRECIO3: data.PRECIO2, PRECIOM: data.PRECIOM, PRECIO1M: data.PRECIO1M, PRECIO2M: data.PRECIO2M, EXIS: data.EXIS }))
    return new Response(Status, Data, Messages, Exception);
  } catch (error) {
    return new Response(500, null, 'Fail getProductosByDESCR', error);
  }
};
/**
 * Obtener ventas de las Cajas Abiertas
 * @returns
 */
const getVentasXCajaTotalDia = async (): Promise<Response> => {
  try {
    const API = await createAxiosInstance();
    const { Data, Status, Messages, Exception } = await API.post(
      `/api/admin/ventasxcaja`
    ).then((i) => i.data);
    return new Response(Status, Data, Messages, Exception);
  } catch (error) {
    return new Response(500, null, 'Fail', error);
  }
};
/**
 * Obtener Clientes
 * @param options Opciones d ebusqueda del cliente
 * @returns
 */
const getClientes = async (options: any) => {
  try {
    const API = await createAxiosInstance();
    const { Data, Status, Messages, Exception } = await API.post(
      `/api/cmbxclientes`,
      options
    ).then((i) => i.data);
    return new Response(Status, Data, Messages, Exception);
  } catch (error) {
    return new Response(500, null, 'Fail getClientes', error);
  }
};

/**
 * Obtener datos del CNE por número de cédula
 * @param find Numero de Cédula a buscar
 * @returns
 */
const getDatosCNE = async (find: number) => {
  try {
    const API = await createAxiosInstance();
    const { Data, Status, Messages, Exception } = await API.get(
      `/v1/cne/search/cid?nat=${find > 40000000 ? 'e' : 'v'}&num=${find}`
    ).then((i) => i.data);
    return new Response(Status, Data, Messages, Exception);
  } catch (error) {
    return new Response(500, null, 'Fail getDatosCNE', error);
  }
};

export const tienenRelacionados = (
  url: string,
  nombreTabla: string,
  include: any | undefined,
  condicion: any | undefined = undefined
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url + `/${nombreTabla}/count`, {
        separate: true,
        include: include,
        where: condicion
      })
      .then((result) => {
        resolve(result.data['count']);
      })
      .catch((err) => {
        reject(
          `${nombreTabla}: ` +
            (err.response
              ? err.response.data
                ? err.response.data
                : err.response
              : err)
        );
      });
  });
};

const loadMesasList = (spinner: boolean = true, url?: string) => {
  return new Promise(async (resolve, reject) => {
    //setloading(spinner);
    await axios
      .post(url + '/adminonline/getmesascpedido')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        // setMessage('err-->' + err);
        reject(err);
      });
    //setloading(false);
  });
};

export {
  getStocks,
  getProductosByDESCR,
  getProductos,
  getVentasXCajaTotalDia,
  getClientes,
  getDatosCNE,
  getDepositos,
  getUsers,
  getMonedas
};
