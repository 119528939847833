import { Grid, Box, Typography, Tooltip, useTheme } from '@mui/material';
import {
  AccountBox,
  AutoAwesomeMosaic,
  Build,
  Edit,
  PointOfSale,
  QueryStats,
  TrendingUp
} from '@mui/icons-material';
import { TipoDialogo } from 'src/utils/Helpers';
import { useState } from 'react';
import {
  SimpleDialogCliente,
  SimpleDialogProducto
} from '../../overview/Panel/Dialogs';
import MyCard from 'src/components/Card/Card';
import MyCardBody from 'src/components/Card/CardBody';
import MyCardFooter from 'src/components/Card/CardFooter';
import MyCardHeader from 'src/components/Card/CardHeader';
import MyCardIcon from 'src/components/Card/CardIcon';
import { DashboardStyle } from 'src/components/styles';
import { makeStyles } from '@mui/styles';
import {
  BoardButtonsStyles,
  CardAddAction,
  CardAddActionArea
} from '../../overview/Panel/styles';
import {
  SimpleDialogPedidos,
  SimpleDialogUsuario,
  SimpleDialogVentasXCaja
} from '../../overview/Panel/Dialogs/Admin';

function Admin() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [tipoDialogo, setTipoDialogo] = useState<TipoDialogo>(TipoDialogo.None);
  const classes = makeStyles(DashboardStyle)();
  const cStyle = makeStyles(BoardButtonsStyles)();

  const handleClickOpen = (value: TipoDialogo) => {
    switch (value) {
      case TipoDialogo.AjusteExistencia:
        setOpen(true);
        break;
      case TipoDialogo.Existencia:
        break;
      case TipoDialogo.Productos:
        break;
      case TipoDialogo.cambioPrecios:
        break;
      case TipoDialogo.None:
        break;

      default:
        break;
    }
    setTipoDialogo(value);
  };
  const handleClose = (value) => {
    setTipoDialogo(TipoDialogo.None);
  };
  return (
    <>
      <MyCard>
        <MyCardHeader color="danger">
          <h4 className={classes.cardTitleWhite}>Administración</h4>
          <p className={classes.cardCategoryWhite}>
            Control de archvivos y mantenimientos del Sistema
          </p>
        </MyCardHeader>
        <MyCardBody className={cStyle.cardBodyPpal}>
          <Grid container spacing={3}>
            {/* <Grid xs={12} sm={6} md={3} item> <Tooltip arrow title="Click Verificar saldos en Caja">
              <CardAddAction onClick={() => handleClickOpen(TipoDialogo.VentasXCaja)}>
                <CardAddActionArea>
                  <MyCard className={cStyle.card}>
                    <MyCardHeader color='danger' stats icon >
                      <MyCardIcon color="primary">
                        <PointOfSale />
                      </MyCardIcon>
                    </MyCardHeader>
                    <MyCardBody className={cStyle.cardBody}>
                      <Box textAlign={'center'} p={0}>
                        <Typography variant='h4' color={theme.colors.error.main} p={theme.spacing(1)}>
                          Ventas x Caja
                        </Typography>
                      </Box>
                    </MyCardBody>
                    <MyCardFooter className={cStyle.cardFoot} stats>
                      <div className={classes.stats} >
                        <Typography color={theme.colors.secondary.main}>
                          Saldos en Cajas
                        </Typography>
                      </div>
                    </MyCardFooter>
                  </MyCard>
                </CardAddActionArea>
              </CardAddAction>
            </Tooltip>
            </Grid> */}
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click Verificar saldos en Caja">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.VentasDiarias)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <TrendingUp />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Ventas Diarias
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Ventas por Día
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click Ver Pedidos Actuales">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.Pedidos)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <AutoAwesomeMosaic />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Pedidos Actuales
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Pedidos actuales
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click Modificar Productos">
                {/* <CardAddAction onClick={() => handleClickOpen(TipoDialogo.Productos)}> */}
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.None)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <Edit />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Productos
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Modificar Productos
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click Verificar saldos en Caja">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.Usuarios)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <AccountBox />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Usuarios
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Editar Usuarios
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click Editar Clientes">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.Clientes)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <PointOfSale />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Clientes
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Editar Clientes
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click Analisis de Ventas por Productos">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.AnalisiProducto)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <QueryStats />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Analisis de Ventas
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Analisis de ventas
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click Mantenimiento del Sistema">
                <CardAddAction
                  onClick={() =>
                    handleClickOpen(TipoDialogo.MantenimientoSistema)
                  }
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="danger" stats icon>
                        <MyCardIcon color="primary">
                          <Build />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Mantenimiento
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Mantenimiento Sistema
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
          </Grid>
        </MyCardBody>
      </MyCard>

      <SimpleDialogUsuario
        tipoDialogo={tipoDialogo}
        open={tipoDialogo === TipoDialogo.Usuarios}
        onClose={handleClose}
      />

      <SimpleDialogVentasXCaja
        tipoDialogo={tipoDialogo}
        open={tipoDialogo === TipoDialogo.VentasXCaja}
        onClose={handleClose}
      />

      <SimpleDialogProducto
        stocks={false}
        tipoDialogo={tipoDialogo}
        open={tipoDialogo === TipoDialogo.Productos}
        onClose={handleClose}
      />

      <SimpleDialogCliente
        tipoDialogo={tipoDialogo}
        open={tipoDialogo === TipoDialogo.Clientes}
        onClose={handleClose}
      />

      <SimpleDialogPedidos
        tipoDialogo={tipoDialogo}
        open={tipoDialogo === TipoDialogo.Pedidos}
        onClose={handleClose}
      />
    </>
  );
}

export default Admin;
