export interface ResponseError extends Error {
  Ruta?: string;
}

export class Response {
  public Status: number;
  public Data: any;
  public Messages: string;
  public Exception?: string;
  constructor(status: number, data: any, msj: string, exception?: string) {
    this.Status = status;
    this.Data = data;
    this.Messages = msj;
    this.Exception = exception;
  }
}
export class Inven {
  public BARRA!: string | null;
  public BASE_MARGE!: number;
  public BLOQUEO!: number;
  public BLOQUEOR!: number;
  public CAL_UTI!: number;
  public CAMBIO!: string | null;
  public CANTIDAD_!: number;
  public CARGOS!: number;
  public CATEGORIA!: string | null;
  public CCAJA!: string | null;
  public CCANTI!: string | null;
  public CCONTABLE!: string;
  public CCOSTO!: string | null;
  public CIFRA!: number;
  public CINVEN!: string | null;
  public CLASE!: number;
  public CODIGO!: string | null;
  public CODIGOC!: string | null;
  public CODIGOP!: string | null;
  public COMPACT!: string | null;
  public COMPUESTO!: string | null;
  public CONC_GASTO!: string | null;
  public CONTENIDO!: string | null;
  public CONTENIDOU!: string | null;
  public CON_SANI!: number;
  public CVENTA!: string | null;
  public DESCR!: string | null;
  public DESDE_O!: Date | null;
  public DGRUPO!: string | null;
  public DIASV!: number;
  public DISPLAYORDER!: number | null;
  public DPTO_CAJAS!: string | null;
  public EDESCR!: string | null;
  public FACTOR!: number;
  public FACTOR1!: number;
  public FACTOR2!: number;
  public FACTOR3!: number;
  public FECHA_M!: Date | null;
  public FECHA_R!: Date | null;
  public FECHA_V1!: Date | null;
  public FECHA_V2!: Date | null;
  public GDENVASE!: string | null;
  public GDESPECI!: string | null;
  public GRADOS!: number;
  public GRUPO!: string | null;
  public GUIAS!: number;
  public HASTA_O!: Date | null;
  public ID!: number;
  public IDSUC!: number;
  public IMPR_NOTACONS!: number;
  public IMP_NAC!: number;
  public JETAVGAS!: number;
  public KP!: string | null;
  public LICOR!: number;
  public LOTES!: number;
  public MARCA!: number;
  public MARCAP!: string | null;
  public MARGEN1!: number;
  public MARGEN1M!: number;
  public MARGEN2!: number;
  public MARGEN2M!: number;
  public MARGEN3!: number;
  public MARGEN3M!: number;
  public MARGEN4!: number;
  public MARGEN4M!: number;
  public MAX!: number;
  public MAXMONTO!: number;
  public MIN!: number;
  public MODO_PRE!: number;
  public MONCARGA!: number;
  public NCON_EXIS!: number;
  public NIVEL!: number;
  public NOMBRE!: string | null;
  public NVENNEGA!: number;
  public IMG!: string | null;
  public ID_IMG!: number | null;
  public OBSERVA!: string | null;
  public OPERADOR!: string | null;
  public PBALANZA!: number;
  public PESO!: number;
  public PESO_UND!: number;
  public PIDECANTI!: number;
  public PIDEOBSE!: number;
  public PIDEPRE!: number;
  public PMAXD!: number;
  public PMAXM!: number;
  public PMIND!: number;
  public PMINM!: number;
  public PRECIO!: number;
  public PRECIO1!: number;
  public PRECIO1M!: number;
  public PRECIO1_E!: number;
  public PRECIO1_O!: number;
  public PRECIO2!: number;
  public PRECIO2M!: number;
  public PRECIO2_E!: number;
  public PRECIO2_O!: number;
  public PRECIO3!: number;
  public PRECIO3M!: number;
  public PRECIO3_O!: number;
  public PRECIOM!: number;
  public PRECIO_CAN!: number;
  public PRECIO_E!: number;
  public PRECIO_O!: number;
  public PRE_CANT!: number;
  public PROMEDIO!: number;
  public PROMO!: string | null;
  public PROMO2!: string | null;
  public PROMO3!: string | null;
  public PROVEE!: string | null;
  public PUBLISHED!: boolean | null;
  public PUNTOS!: number;
  public REFE!: string | null;
  public REGULADO!: number;
  public REG_CANT!: number;
  public REG_DIAS!: number;
  public SELETRAN!: number;
  public SERIAL!: number;
  public SERVI!: number;
  public SERVIBP!: number;
  public SHOWHP!: boolean | null;
  public SOLOENT!: number;
  public SYNC!: number;
  public TCOS1!: string | null;
  public TCOS2!: string | null;
  public TIPO!: string | null;
  public TIPOFAR!: number;
  public TIPOO!: number | null;
  public TIPOP!: number;
  public TIPO_LICOR!: number;
  public TIVA!: number;
  public TIVA2!: number;
  public TOPERA!: number;
  public TPESO!: number;
  public TROQUELA!: number;
  public ULTIMAU!: string | null;
  public ULTIMO!: number;
  public ULT_ACTU!: Date | null;
  public ULT_COMPRA!: Date | null;
  public ULT_VENTA!: Date | null;
  public UND_MEDIDA!: string | null;
  public UNIDAD!: number;
  public UNIDADC!: string | null;
  public UNIDADE!: number;
  public UNIDADV!: string | null;
  public UNIDADVC!: number;
  public PTSyncRecordDate!: Date | null;
  public DTOLLOT!: number | null;
  public NOCONVER!: number | null;
  public TECLA!: number | null;
}
