import {
  BrowserRouter,
  Route,
  Router,
  Routes,
  useRoutes
} from 'react-router-dom';
import router from 'src/router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { AuthProvider } from './contexts/AuthContext';
import { LoginPage } from './layouts/auth';
import { PrivateRoute } from './privateroute';
import { Dashboard } from '@mui/icons-material';

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <AuthProvider>{content}</AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
