import { Grid, Box, Typography, Tooltip, useTheme } from '@mui/material';
import {
  DeliveryDining,
  Inventory2,
  PersonPinCircle,
  PostAdd,
  WhatsApp
} from '@mui/icons-material';
import { useState } from 'react';
import {
  BoardButtonsStyles,
  CardAddAction,
  CardAddActionArea
} from '../../overview/Panel/styles';
import {
  SimpleDialogCliente,
  SimpleDialogProducto
} from '../../overview/Panel/Dialogs';
import { TipoDialogo } from 'src/utils/Helpers';
import MyCard from 'src/components/Card/Card';
import MyCardBody from 'src/components/Card/CardBody';
import MyCardFooter from 'src/components/Card/CardFooter';
import MyCardHeader from 'src/components/Card/CardHeader';
import MyCardIcon from 'src/components/Card/CardIcon';
import { DashboardStyle } from 'src/components/styles';
import { makeStyles } from '@mui/styles';
import { RedesDeliveryFormDialog } from 'src/content/overview/Panel/Dialogs/RedesSociales';

function RedesSociales() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [tipoDialogo, setTipoDialogo] = useState<TipoDialogo>(TipoDialogo.None);

  const handleClickOpen = (value: TipoDialogo) => {
    switch (value) {
      case TipoDialogo.AjusteExistencia:
        setOpen(true);
        break;
      case TipoDialogo.Existencia:
        break;
      case TipoDialogo.Productos:
        break;
      case TipoDialogo.cambioPrecios:
        break;
      case TipoDialogo.None:
        break;

      default:
        break;
    }
    setTipoDialogo(value);
  };

  const handleClose = (value) => {
    setTipoDialogo(TipoDialogo.None);
  };

  const classes = makeStyles(DashboardStyle)();
  const cStyle = makeStyles(BoardButtonsStyles)();
  return (
    <>
      <MyCard>
        <MyCardHeader color="danger">
          <h4 className={classes.cardTitleWhite}>Redes Sociales</h4>
          <p className={classes.cardCategoryWhite}>
            Area de Trabajo para Redes Sociales e Internet
          </p>
        </MyCardHeader>
        <MyCardBody className={cStyle.cardBodyPpal}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={6} md={3} item>
              <Tooltip arrow title="Click Acceder a Existencias">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.redesExistencia)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="error" stats icon>
                        <MyCardIcon color="primary">
                          <Inventory2 />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Existencias
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Consultar Existencias
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click para ubicar los Datos de un Cliente">
                <CardAddAction
                  onClick={() => handleClickOpen(TipoDialogo.redesDatosCliente)}
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="error" stats icon>
                        <MyCardIcon color="primary">
                          <PersonPinCircle />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Ubicar Cliente
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Ubicación de un cliente
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click para Registrar envío">
                <CardAddAction
                  onClick={() =>
                    handleClickOpen(TipoDialogo.redesEnvioDelivery)
                  }
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="error" stats icon>
                        <MyCardIcon color="primary">
                          <DeliveryDining />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Registrar Delivery
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Registra un Delivery
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click Genera un POS con IA para Instagram">
                <CardAddAction
                  onClick={() =>
                    handleClickOpen(TipoDialogo.redesPostInstagramIA)
                  }
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="error" stats icon>
                        <MyCardIcon color="primary">
                          <PostAdd />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Post Instagram
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Generar un POST con IA
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              {' '}
              <Tooltip arrow title="Click chatear con whatsapp Bot IA">
                <CardAddAction
                  onClick={() =>
                    handleClickOpen(TipoDialogo.redesPostInstagramIA)
                  }
                >
                  <CardAddActionArea>
                    <MyCard className={cStyle.card}>
                      <MyCardHeader color="error" stats icon>
                        <MyCardIcon color="primary">
                          <WhatsApp />
                        </MyCardIcon>
                      </MyCardHeader>
                      <MyCardBody className={cStyle.cardBody}>
                        <Box textAlign={'center'} p={0}>
                          <Typography
                            variant="h4"
                            color={theme.colors.error.main}
                            p={theme.spacing(1)}
                          >
                            Whatsapp Bot
                          </Typography>
                        </Box>
                      </MyCardBody>
                      <MyCardFooter className={cStyle.cardFoot} stats>
                        <div className={classes.stats}>
                          <Typography color={theme.colors.secondary.main}>
                            Chatea con Whatsapp Bot IA
                          </Typography>
                        </div>
                      </MyCardFooter>
                    </MyCard>
                  </CardAddActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
          </Grid>
        </MyCardBody>
      </MyCard>
      {tipoDialogo === TipoDialogo.redesDatosCliente && (
        <SimpleDialogCliente
          message={'Puedes indicar: C.I., Teléfono, Nombre'}
          tipoDialogo={tipoDialogo}
          open={tipoDialogo === TipoDialogo.redesDatosCliente}
          onClose={handleClose}
        />
      )}
      {tipoDialogo === TipoDialogo.redesExistencia && (
        <SimpleDialogProducto
          tipoDialogo={tipoDialogo}
          open={tipoDialogo === TipoDialogo.redesExistencia}
          onClose={handleClose}
        />
      )}
      {tipoDialogo === TipoDialogo.redesEnvioDelivery && (
        <RedesDeliveryFormDialog
          tipoDialogo={tipoDialogo}
          open={tipoDialogo === TipoDialogo.redesEnvioDelivery}
          onClose={handleClose}
        />
      )}
    </>
  );
}

export default RedesSociales;
