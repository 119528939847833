import { Box, Container, Card, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Footer } from 'src/components/Footer';
import PageHeader from 'src/content/dashboards/PageHeader';
import Admin from './Admin';

const OverviewWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

const AdminPage = () => {
  return (
    <>
      <Helmet>
        <title>Aministración RetailView</title>
      </Helmet>
      {/* <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper> */}
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item lg={8} xs={12}>
            <Admin />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default AdminPage;
