import {
  Avatar,
  Button,
  Card,
  ListItemAvatar,
  OutlinedInput,
  SnackbarOrigin,
  TableCell,
  TextField,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';
import { hexToRgb } from 'src/utils/Helpers';

const generateCardHeaderStyle = (color, boxShadow) => {
  return {
    background: `linear-gradient(60deg, ${color}, ${color})`,
    boxShadow: boxShadow
  };
};

const TitleWrapper = styled(Typography)(
  ({ theme }) => `color: ${theme.colors.alpha.white[100]};`
);

const CardStyles = () => {
  const theme = useTheme();
  return {
    card: {
      border: '0',
      marginBottom: '0',
      marginTop: '30px',
      borderRadius: '6px',
      color: `rgba(${hexToRgb(theme.colors.alpha.black[100])}, 0.87)`,
      background: theme.colors.alpha.white[100],
      width: '100%',
      boxShadow: `0 1px 4px 0 rgba(${hexToRgb(
        theme.colors.alpha.black[100]
      )}, 0.14)`,
      display: 'flex',
      minWidth: '0',
      fontSize: '.875rem'
    },
    cardPlain: {
      background: 'transparent',
      boxShadow: 'none'
    },
    cardProfile: {
      marginTop: '30px'
    },
    cardChart: {
      '& p': {
        marginTop: '0px',
        paddingTop: '0px'
      }
    }
  };
};

const CardAvatarStyle = () => {
  const theme = useTheme();

  return {
    cardAvatar: {
      '&$cardAvatarProfile img': {
        width: '100%',
        height: 'auto'
      }
    },
    cardAvatarProfile: {
      maxWidth: '130px',
      maxHeight: '130px',
      margin: '-50px auto 0',
      borderRadius: '50%',
      overflow: 'hidden',
      padding: '0',
      boxShadow: theme.colors.shadows.boxShadow,
      '&$cardAvatarPlain': {
        marginTop: '0'
      }
    },
    cardAvatarPlain: {}
  };
};

const CardBodyStyle = () => {
  const theme = useTheme();
  return {
    cardBody: {
      padding: '0.9375rem 20px',
      flex: '1 1 auto',
      WebkitBoxFlex: '1'
    },
    cardBodyPlain: {
      paddingLeft: '5px',
      paddingRight: '5px'
    },
    cardBodyProfile: {
      marginTop: '15px'
    }
  };
};

const CardFooterStyle = () => {
  const theme = useTheme();
  return {
    cardFooter: {
      padding: '0',
      paddingTop: '10px',
      margin: '0 15px 10px',
      borderRadius: '0',
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      backgroundColor: 'transparent',
      border: '0'
    },
    cardFooterProfile: {
      marginTop: '-15px'
    },
    cardFooterPlain: {
      paddingLeft: '5px',
      paddingRight: '5px',
      backgroundColor: 'transparent'
    },
    cardFooterStats: {
      borderTop: `1px solid ${theme.colors.gray.light}`,
      marginTop: '20px',
      '& svg': {
        position: 'relative',
        top: '4px',
        marginRight: '3px',
        marginLeft: '3px',
        width: '16px',
        height: '16px'
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '16px',
        position: 'relative',
        top: '4px',
        marginRight: '3px',
        marginLeft: '3px'
      }
    },
    cardFooterChart: {
      borderTop: `1px solid ${theme.colors.gray.light}`
    }
  };
};

const CardHeaderStyle = () => {
  const theme = useTheme();
  return {
    cardHeader: {
      padding: '0.75rem 1.25rem',
      marginBottom: '0',
      borderBottom: 'none',
      background: 'transparent',
      zIndex: '3 !important',
      margin: '0 15px',
      color: theme.colors.alpha.white[100],
      flexDirection: 'column' as any,
      position: 'relative' as any,
      '&:first-child': {
        borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'
      },
      '&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$darkCardHeader':
        {
          '&:not($cardHeaderIcon)': {
            borderRadius: '3px',
            marginTop: '-20px',
            padding: '15px'
          }
        },
      '&$cardHeaderStats svg': {
        fontSize: '36px',
        lineHeight: '56px',
        textAlign: 'center',
        width: '36px',
        height: '36px',
        margin: '10px 10px 4px'
      },
      '&$cardHeaderStats i,&$cardHeaderStats .material-icons': {
        fontSize: '36px',
        lineHeight: '56px',
        width: '56px',
        height: '56px',
        textAlign: 'center',
        overflow: 'unset',
        marginBottom: '1px'
      },
      '&$cardHeaderStats$cardHeaderIcon': {
        textAlign: 'right'
      }
    },
    cardHeaderPlain: {
      marginLeft: '0px !important',
      marginRight: '0px !important'
    },
    cardHeaderStats: {
      '& $cardHeaderIcon': {
        textAlign: 'right'
      },
      '& h1,& h2,& h3,& h4,& h5,& h6': {
        margin: '0 !important'
      }
    },
    cardHeaderIcon: {
      '&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$darkCardHeader':
        {
          background: 'transparent',
          boxShadow: 'none'
        },
      '& i,& .material-icons': {
        width: '33px',
        height: '33px',
        textAlign: 'center',
        lineHeight: '33px'
      },
      '& svg': {
        width: '24px',
        height: '24px',
        textAlign: 'center',
        lineHeight: '33px',
        margin: '5px 4px 0px'
      }
    },
    warningCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.warning.main,
        theme.colors.shadows.warningBoxShadow
      )
    },
    successCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.success.main,
        theme.colors.shadows.successBoxShadow
      )
    },
    dangerCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.error.main,
        theme.colors.shadows.dangerBoxShadow
      )
    },
    infoCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.info.main,
        theme.colors.shadows.infoBoxShadow
      )
    },
    primaryCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.primary.main,
        theme.colors.shadows.primaryBoxShadow
      )
    },
    roseCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.rose.main,
        theme.colors.shadows.roseBoxShadow
      )
    },
    darkCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.gray.main,
        theme.colors.shadows.darkBoxShadow
      )
    }
  };
};

const CardIconStyle = () => {
  const theme = useTheme();
  return {
    cardIcon: {
      '&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$darkCardHeader':
        {
          borderRadius: '3px',
          backgroundColor: theme.colors.gray.light,
          padding: '15px',
          marginTop: '-20px',
          marginRight: '15px',
          float: 'left'
        }
    },
    warningCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.warning.main,
        theme.colors.shadows.warningBoxShadow
      )
    },
    successCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.success.main,
        theme.colors.shadows.successBoxShadow
      )
    },
    dangerCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.error.main,
        theme.colors.shadows.dangerBoxShadow
      )
    },
    infoCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.info.main,
        theme.colors.shadows.infoBoxShadow
      )
    },
    primaryCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.primary.main,
        theme.colors.shadows.primaryBoxShadow
      )
    },
    roseCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.rose.main,
        theme.colors.shadows.roseBoxShadow
      )
    },
    darkCardHeader: {
      color: theme.colors.alpha.white[100],
      '&:not($cardHeaderIcon)': generateCardHeaderStyle(
        theme.colors.gray.main,
        theme.colors.shadows.darkBoxShadow
      )
    }
  };
};
const DashboardStyle = () => {
  const theme = useTheme();
  return {
    successText: {
      color: theme.colors.success.main
    },
    upArrowCardCategory: {
      width: '16px',
      height: '16px'
    },
    stats: {
      color: theme.colors.gray.main,
      display: 'inline-flex',
      fontSize: '12px',
      lineHeight: '22px',
      '& svg': {
        top: '4px',
        width: '16px',
        height: '16px',
        position: 'relative',
        marginRight: '3px',
        marginLeft: '3px'
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        top: '4px',
        fontSize: '16px',
        position: 'relative',
        marginRight: '3px',
        marginLeft: '3px'
      }
    },
    cardCategory: {
      color: theme.colors.gray.light,
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      paddingTop: '10px',
      marginBottom: '0'
    },
    cardCategoryWhite: {
      color: 'rgba(' + hexToRgb(theme.colors.alpha.white[100]) + ',.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    cardTitle: {
      color: theme.colors.gray.main,
      marginTop: '0px',
      minHeight: 'auto',
      fontWeight: '300',
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: '3px',
      textDecoration: 'none',
      '& small': {
        color: theme.colors.gray.light,
        fontWeight: '400',
        lineHeight: '1'
      }
    },
    cardTitleWhite: {
      color: theme.colors.alpha.white[100],
      marginTop: '0px',
      minHeight: 'auto',
      fontWeight: '300',
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: '3px',
      textDecoration: 'none',
      '& small': {
        color: theme.colors.gray.main,
        fontWeight: '400',
        lineHeight: '1'
      }
    }
  };
};

export {
  TitleWrapper,
  CardHeaderStyle,
  CardStyles,
  CardAvatarStyle,
  CardBodyStyle,
  CardIconStyle,
  CardFooterStyle,
  DashboardStyle
};
