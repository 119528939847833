import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  ListItemAvatar,
  OutlinedInput,
  Paper,
  SnackbarOrigin,
  TableCell,
  TextField,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import { hexToRgb } from 'src/utils/Helpers';

const TitleWrapper = styled(Typography)(
  ({ theme }) => `
              color: ${theme.colors.alpha.white[100]};  
      `
);
const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
          margin: ${theme.spacing(2, 0, 1, -0.5)};
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: ${theme.spacing(1)};
          padding: ${theme.spacing(0.5)};
          border-radius: 60px;
          height: ${theme.spacing(5.5)};
          width: ${theme.spacing(5.5)};
          background: ${
            theme.palette.mode === 'dark'
              ? theme.colors.alpha.trueWhite[30]
              : alpha(theme.colors.alpha.black[100], 0.07)
          };
        
          img {
            background: ${theme.colors.alpha.trueWhite[100]};
            padding: ${theme.spacing(0.5)};
            display: block;
            border-radius: inherit;
            height: ${theme.spacing(4.5)};
            width: ${theme.spacing(4.5)};
          }
      `
);
const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
              background: ${theme.colors.alpha.black[10]};
              color: ${theme.colors.error.main}; 
              width: ${theme.spacing(8)};
              height: ${theme.spacing(8)};
      `
);
const CardAddAction = styled(Card)(
  ({ theme }) => `
              height: 100%;
              color: ${theme.colors.primary.main};
              transition: ${theme.transitions.create(['all'])};
              background-color: transparent;
              .MuiCardActionArea-root {
                height: 100%;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-bottom:0;
              }
              
              .MuiTouchRipple-root {
                opacity: .2;
              }
              
              &:hover {
                border: ${theme.colors.primary.main} dashed 1px;
                background-color: ${theme.colors.primary.main};
                cursor: pointer;
                z-index:1000;
                margin-bottom: 0 !important;
            }
      `
);

const CardAddActionArea = styled(CardActionArea)(
  ({ theme }) => `
        display: flex;
        justifyContent: center;
        alignItems: center;
        height: 100%;
        padding:0;
    `
);

const OutlinedTextFieldWrapper = styled(TextField)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      margin:  ${theme.spacing(1)}
  `
);
const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      margin:  ${theme.spacing(1)}
  `
);

const TablecellHeaderDialog = styled(TableCell)(
  ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      max-Width:10rem;
      text-align: right;
  `
);
const TablecelDialog = styled(TableCell)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      max-Width:10rem;
      text-align: right;
  `
);
const ButtonSearch = styled(Button)(
  ({ theme }) => `
      margin-right: -${theme.spacing(1)};
  `
);

interface State extends SnackbarOrigin {
  openSb: boolean;
  message: string;
}

const AvatarPrimary = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.primary.lighter};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
  `
);
const ListItemAvatarWrapper = styled(ListItemAvatar)(
  ({ theme }) => `
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(0.5)};
    border-radius: 60px;
    background: ${
      theme.palette.mode === 'dark'
        ? theme.colors.alpha.trueWhite[30]
        : alpha(theme.colors.alpha.black[100], 0.07)
    };
  
    img {
      background: ${theme.colors.alpha.trueWhite[100]};
      padding: ${theme.spacing(0.5)};
      display: block;
      border-radius: inherit;
      height: ${theme.spacing(4.5)};
      width: ${theme.spacing(4.5)};
    }
  `
);

const BoardButtonsStyles = () => {
  const theme = useTheme();
  return {
    cardBodyPpal: {
      padding: 0,
      textAlign: 'center' as any,
      backgroundColor: theme.colors.primary.lighter
    },
    cardBody: {
      padding: `${theme.spacing(0.5)} !important`,
      textAlign: 'center' as any
    },
    cardFoot: {
      marginTop: '0px !important',
      textAlign: 'center' as any
    },
    card: {
      marginBottom: '0 !important'
    },
    cardCategoryWhite: {
      color: 'rgba(' + hexToRgb(theme.colors.alpha.white[100]) + ',.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    cardTitle: {
      color: theme.colors.gray.main,
      marginTop: '0px',
      minHeight: 'auto',
      fontWeight: '300',
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: '3px',
      textDecoration: 'none',
      '& small': {
        color: theme.colors.gray.light,
        fontWeight: '400',
        lineHeight: '1'
      }
    },
    cardTitleWhite: {
      color: theme.colors.alpha.white[100],
      marginTop: '0px',
      minHeight: 'auto',
      fontWeight: '300',
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: '3px',
      textDecoration: 'none',
      '& small': {
        color: theme.colors.gray.main,
        fontWeight: '400',
        lineHeight: '1'
      }
    }
  };
};

const StyledDialogPaper = styled(Paper)`
  background-color: transparent;
  padding: 0;
  overflow: hidden;
`;
export {
  TitleWrapper,
  AvatarAddWrapper,
  AvatarWrapper,
  CardAddAction,
  OutlinedInputWrapper,
  OutlinedTextFieldWrapper,
  ButtonSearch,
  TablecellHeaderDialog,
  TablecelDialog,
  AvatarPrimary,
  ListItemAvatarWrapper,
  CardAddActionArea,
  BoardButtonsStyles,
  StyledDialogPaper
};
