import {
  Grid,
  useTheme,
  Dialog,
  Container,
  Divider,
  DialogContent,
  Alert,
  TextField,
  Box,
  Button,
  MenuItem,
  Stack,
  Chip,
  Autocomplete,
  Typography
} from '@mui/material';
import { DeliveryDining, EditTwoTone } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import { StyledDialogPaper } from '../styles';
import MyCard from 'src/components/Card/Card';
import MyCardBody from 'src/components/Card/CardBody';
import MyCardHeader from 'src/components/Card/CardHeader';
import { DashboardStyle } from 'src/components/styles';
import { makeStyles } from '@mui/styles';
import { ChoferesDelivery, UsersData, ZonasDelivery } from 'src/Data';
import { getProductosByDESCR } from 'src/services';
import { BuscraCliente, TipoDialogo } from 'src/utils/Helpers';
import Spinner from 'src/components/spinner';
import { useReactToPrint } from 'react-to-print';
import { format } from 'date-fns';
import { DeliveryPrint } from '../printer/delivery';

const RedesDeliveryFormDialog = (props) => {
  const theme = useTheme();
  const { onClose, selectedValue, open, message } = props;
  const [formData, setFormData] = useState({
    telefono: '',
    nombreApellido: '',
    cedula: '',
    articulos: [],
    precioDelivery: 0,
    total: 0,
    metodoPago: '',
    numeroReferencia: '',
    zonaEntrega: '',
    chofer: '',
    direccion: '',
    vuelto: 0,
    totalcondelivery: 0
  });
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [encontrados, setEncontrados] = useState(undefined);
  const [printing, setPrinting] = useState(false);
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: 'Print This Document',
    onBeforePrint: () => {
      setLoading(true);
      setPrinting(true);
    },
    onAfterPrint: () => {
      setLoading(false);
      setPrinting(false);
    },
    removeAfterPrint: true,
    content: () => contentToPrint.current
  });

  // const [articulos, setArticulos] = useState([]);
  const fetchCliente = async (descr: string) => {
    try {
      setLoading(true);
      const data = await BuscraCliente(descr, TipoDialogo.redesDatosCliente);
      setEncontrados(data[0]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // Función para manejar el cambio en el campo de búsqueda
  const handleInputChange = async (event, newInputValue) => {
    if (newInputValue?.length > 3) {
      const { Data, Messages } = await getProductosByDESCR(newInputValue);
      setOptions(Data);
    } else {
      setOptions([]);
    }
    setInputValue(newInputValue);
  };

  // Función para manejar la selección de un elemento de los resultados
  const handleSelectItem = (event, value) => {
    if (value) {
      setFormData({ ...formData, articulos: value });
    }
  };
  const handleClose = () => {
    onClose(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBlur = (e) => {
    e.preventDefault();
    if (!encontrados && e.target.value.length >= 7) {
      if (e.target.name === 'telefono' && formData.cedula.length) return;
      fetchCliente(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Aquí podrías enviar los datos a un servidor o manejarlos como necesites
    handleClose(); // Cierra el diálogo después de enviar el formulario
  };

  const classes = makeStyles(DashboardStyle)();
  useEffect(() => {
    if (formData.articulos) {
      let total =
        formData.total +
        formData.articulos[formData.articulos.length - 1]?.PRECIO1;
      if (isNaN(total)) total = 0;
      setFormData({ ...formData, total: total });
    }
  }, [formData.articulos]);

  useEffect(() => {
    if (encontrados) {
      setFormData({
        ...formData,
        cedula: encontrados.RIF,
        nombreApellido: encontrados.NOMBRE,
        direccion: encontrados.CNE
          ? `${encontrados.CNE.direccion}\n${encontrados?.CNE.municipality}, ${encontrados?.CNE.parish}, ${encontrados?.CNE.state}`
          : ''
      });
    }
  }, [encontrados]);

  useEffect(() => {
    if (formData.cedula.length === 0) setEncontrados(undefined);
  }, [formData.cedula]);

  useEffect(() => {
    setFormData({
      ...formData,
      totalcondelivery: formData.total + formData.precioDelivery
    });
  }, [formData.total, formData.precioDelivery]);

  useEffect(() => {
    if (formData.zonaEntrega.length)
      setFormData({
        ...formData,
        precioDelivery: ZonasDelivery.find(
          (z) => z.name === formData.zonaEntrega
        )?.value
      });
  }, [formData.zonaEntrega]);
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperComponent={StyledDialogPaper}
      >
        <DialogContent sx={{ p: 0, m: 0 }}>
          <MyCard>
            <MyCardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                Formulario para Delivery
              </h4>
              {message && <Alert severity="info">{message}</Alert>}
              {/*  <p className={classes.cardCategoryWhite}>
                                {encontrados?.length > 0 ? `${encontrados?.length} Clientes (${filters})` : null}
                            </p> */}
            </MyCardHeader>
            <MyCardBody>
              <Container
                maxWidth="sm"
                sx={{ maxHeight: '60vh', p: '1 !important', overflow: 'auto' }}
              >
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ padding: theme.spacing(1) }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        label="Chofer"
                        variant="outlined"
                        select
                        fullWidth
                        name="chofer"
                        value={formData.chofer}
                        onChange={handleChange}
                      >
                        {ChoferesDelivery.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.ci}
                            sx={{ textWrap: 'pretty', maxWidth: '100%' }}
                          >
                            {`🏍 ${option.name}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        // limitTags={1}
                        inputValue={inputValue}
                        onChange={handleSelectItem}
                        onInputChange={handleInputChange}
                        options={options}
                        getOptionLabel={(option) =>
                          `${option?.DESCR} $${option?.PRECIO1.toFixed(2)}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Buscar productos"
                            placeholder="Escribe para buscar..."
                          />
                        )}
                        sx={{ width: '500px' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider>Cliente</Divider>
                    </Grid>
                    {loading && <Spinner />}
                    {!loading && (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            label="Cédula"
                            variant="outlined"
                            fullWidth
                            name="cedula"
                            value={formData.cedula}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="N° Telef"
                            variant="outlined"
                            fullWidth
                            name="telefono"
                            value={formData.telefono}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Nombre y Apellido"
                            variant="outlined"
                            fullWidth
                            name="nombreApellido"
                            inputProps={{}}
                            value={formData.nombreApellido}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            label="Dirección"
                            variant="outlined"
                            fullWidth
                            name="direccion"
                            value={formData.direccion}
                            onChange={handleChange}
                            multiline
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Zona de entrega"
                            variant="outlined"
                            select
                            defaultValue="Zona 1"
                            fullWidth
                            name="zonaEntrega"
                            value={formData.zonaEntrega}
                            onChange={handleChange}
                          >
                            {ZonasDelivery.map((option) => (
                              <MenuItem
                                key={option.name}
                                value={option.name}
                                sx={{ textWrap: 'pretty', maxWidth: '100%' }}
                              >
                                {`${option.name}\n(${option.locations.join(
                                  ', '
                                )})`}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Total Delivery"
                            variant="outlined"
                            fullWidth
                            name="precioDelivery"
                            value={formData.precioDelivery}
                            onChange={handleChange}
                            InputProps={{
                              inputProps: {
                                style: {
                                  textAlign: 'right',
                                  fontSize: '1.2rem',
                                  fontWeight: '800'
                                }
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Total Pedido"
                            variant="outlined"
                            fullWidth
                            name="total"
                            value={formData.total}
                            onChange={handleChange}
                            InputProps={{
                              inputProps: {
                                style: {
                                  textAlign: 'right',
                                  fontSize: '1.2rem',
                                  fontWeight: '800'
                                }
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item xs={6}>
                            <TextField
                              label="Método de Pago"
                              variant="outlined"
                              fullWidth
                              name="metodoPago"
                              value={formData.metodoPago}
                              onChange={handleChange}
                            />
                          </Grid>
                          {formData.metodoPago.toLowerCase() === 'efectivo' && (
                            <Grid item xs={6}>
                              <TextField
                                label="Número de referencia"
                                variant="outlined"
                                fullWidth
                                name="numeroReferencia"
                                value={formData.numeroReferencia}
                                onChange={handleChange}
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Total + Delivery"
                            variant="outlined"
                            fullWidth
                            name="totalcondelivery"
                            value={formData.totalcondelivery}
                            onChange={handleChange}
                            InputProps={{
                              inputProps: {
                                style: {
                                  textAlign: 'right',
                                  fontSize: '1.2rem',
                                  color: 'blue',
                                  fontWeight: '800'
                                }
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Vuelto"
                            variant="outlined"
                            fullWidth
                            name="vuelto"
                            value={formData.vuelto}
                            onChange={handleChange}
                            InputProps={{
                              inputProps: {
                                style: {
                                  textAlign: 'right',
                                  fontSize: '1rem',
                                  color: 'red',
                                  fontWeight: '800'
                                }
                              }
                            }}
                          />
                        </Grid>
                      </>
                    )}

                    {/* Repite para cada campo */}
                  </Grid>
                </form>
              </Container>
            </MyCardBody>
            <Box
              p={1}
              alignItems="center"
              textAlign={'end'}
              justifyContent="space-between"
              width={'100%'}
              sx={{
                background: theme.colors.primary.lighter,
                p: 1
              }}
            >
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handlePrint} color="primary">
                Imprimir
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                color="primary"
                startIcon={<EditTwoTone />}
              >
                Guardar
              </Button>
            </Box>
          </MyCard>
        </DialogContent>
      </Dialog>
      <Box sx={{ display: 'none' }}>
        <DeliveryPrint formData={formData} ref={contentToPrint} />
      </Box>
    </>
  );
};

const RedesEnvio = () => {
  return <></>;
};
const RedesPostIAInstagram = () => {
  return <></>;
};
export { RedesEnvio, RedesPostIAInstagram, RedesDeliveryFormDialog };
