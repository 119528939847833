// AuthContext.tsx
import { bool } from 'prop-types';
import React, { Dispatch, createContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { UsersData } from 'src/Data';
import { UserLoginType } from 'src/types';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (user: string, password: string) => void;
  logout: () => void;
  checkAuth?: (password: string) => boolean;
  userLogin: any;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  checkAuth: () => false,
  userLogin: undefined
});

export const AuthProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userLogin, setUserLogin] = useState<UserLoginType>(undefined);
  const navigate = useNavigate(); // Obtiene la función de navegación

  const checkAuth = (password: string) => {
    return password === userLogin.password && userLogin.nivel === 1;
  };

  const login = (user: string, password: string) => {
    let autorize: boolean = false;
    const userdata = UsersData.find((u) => u.user === user);
    if (userdata && userdata.password === password) {
      autorize = true;
    } else autorize = false;
    if (autorize) {
      setIsAuthenticated(true);
      setUserLogin(userdata);
    } else {
      setIsAuthenticated(false);
      setUserLogin(undefined);
      navigate('/login');
      return;
    }
    switch (userdata?.nivel) {
      case 1:
        navigate('/');
        break;
      case 2:
        navigate('/');
        break;
      case 3:
        navigate('/redessociales');
        break;
      case 4:
        navigate('/reportes');
        break;
      case 5:
        navigate('/clientes');
        break;
      case 6:
        navigate('/reportes');
        break;

      default:
        break;
    }
  };

  const logout = () => {
    // Lógica de cierre de sesión
    setIsAuthenticated(false);
    navigate('/login');
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, userLogin, checkAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
